.topics {
  display: flex;
  justify-content: center;
  margin-bottom: rem(24px);
  gap: rem(32px);

  @include mq($until: small-desktop) {
    flex-wrap: wrap;
    gap: rem(16px);
  }

  @include mq($until: iphone-11) {
    justify-content: flex-start;
  }

  > a {
    display: block;
    border-bottom: rem(2px) solid $white;
    cursor: pointer;
    background: none;
    color: $font-3;
    transition: 0.3s all;
    text-decoration: none !important;
    font-size: rem(18px);
    white-space: nowrap;

    &.active,
    &:hover {
      border-bottom: rem(2px) solid $main-500;
      color: $main-500;
    }

    &:focus {
      color: $main-500;
      @include focus;
      border-bottom: unset;
    }
  }
}

.small-margin {
  margin-top: rem(64px);

  @include mq($until: iphone-11) {
    margin-top: rem(32px);
  }
}

.tall-margin {
  margin-top: rem(124px);

  a {
    @include link-styling;
  }
}
