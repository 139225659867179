.plan-collapse-page {
  &__table-set {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;

    > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(7, 1fr);
      font-weight: 500;
      color: $main-500;
    }

    > div:nth-child(1) {
      background: $white;
      color: $font-3;
      font-weight: 500;
    }
  }

  &__table-blocks {
    > div {
      padding: rem(25px) 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey-200;

      @include mq($until: desktop) {
        padding: rem(25px) rem(5px);
      }
    }

    .plan-collapse-page__table-each-title {
      border-left: rem(1px) solid $grey-200 !important;
    }
  }
}
