.fresh-investigation {
  @include mq($until: 640px) {
    margin-bottom: rem(192px);
  }

  .info-block {
    margin-bottom: rem(97px);
  }

  .fresh-investigation-action-buttons {
    display: flex;
    justify-content: flex-end;
    margin: rem(0 auto);
    max-width: rem(848px);
    width: 100%;

    @include mq($until: small-desktop) {
      max-width: rem(480px);
      padding: rem(0 15px);
    }

    button {
      padding: rem(15px 40px);
    }

    .add-company {
      margin-right: rem(32px);
    }
  }
}
