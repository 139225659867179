.customers-page {
  @include mq($until: desktop) {
    padding: rem(0 20px);
  }

  .benefits-wrapper {
    background: $white;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;

    @include mq($until: tablet) {
      margin: 0 auto;
    }
  }

  .info-block button {
    margin-top: rem(30px);
  }

  .topics {
    > div:nth-child(1) {
      color: $main-500;
      border-bottom: 2px $main-500 solid;
    }
  }

  .benefits-blog__left {
    max-width: rem(592px);
    width: 100%;

    @include mq($until: desktop) {
      width: auto;
      max-width: rem(580px);
    }
  }

  .carousel {
    margin-bottom: rem(196px);
  }
}
