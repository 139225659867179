.book-demo {
  width: 100%;
  margin: rem(30px auto 54px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white-secondary;
  padding: rem(0px) rem(50px) rem(80px) rem(50px);

  @include mq($until: tablet) {
    padding: rem(0px) rem(15px) rem(15px) rem(15px);
  }

  .input-wrapper {
    @include mq($until: small-desktop) {
      input {
        width: 100%;
      }

      span {
        left: rem(18px);
      }
    }
  }

  input,
  button {
    width: 100%;
  }

  > div {
    width: 100%;
    max-width: rem(592px);
    margin-top: rem(32px);

    @include mq($until: tablet) {
      .input-error-label {
        left: rem(-15px) !important;
      }
    }

    > div {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  &__get {
    display: flex;
    justify-content: space-between;

    @include mq($until: small-desktop) {
      margin-top: 0 !important;
    }

    @include mq($until: tablet) {
      .has-error {
        margin-bottom: rem(25px) !important;
      }

      .input-error-label {
        left: rem(-15px) !important;
      }
    }

    @include mq($from: tablet, $until: small-desktop) {
      margin-top: rem(32px) !important;
    }

    @include mq($until: tablet) {
      display: block;
    }

    > div {
      width: 100%;

      &:not(:last-child) {
        margin-right: rem(12.5px);
      }

      &:not(:first-child) {
        margin-left: rem(12.5px);
      }

      @include mq($until: tablet) {
        margin: rem(10px) auto !important;
        max-width: 100% !important;
      }
    }
  }

  h1 {
    margin: rem(80px 40px 0);
    color: $font-4;
    text-align: center;
    font-size: rem(35px);

    > span {
      display: block;
      line-height: 1.5;
    }

    @include mq($until: iphone-11) {
      margin: rem(80px 0 0);
      text-align: start;
      font-size: rem(40px);
      line-height: rem(44px);
    }
  }

  > span {
    text-align: center;
    margin-top: rem(32px);
    color: $font-3;
    max-width: rem(418px);
    width: 100%;

    > a {
      color: $main-500;
    }
  }

  .conditions-wrapper {
    @include mq($until: desktop) {
      max-width: rem(368px);
    }

    @include mq($until: iphone-11) {
      align-self: start;
      text-align: inherit;
    }

    a:focus {
      @include focus;
    }
  }
}

.book-demo-loader {
  margin: rem(30px auto 54px);
  height: rem(596px);
  width: 100%;
  max-width: rem($layout-base-2);
  background-color: $white-secondary;
}
