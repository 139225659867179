.web-technologies {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: rem(192px) auto 0;

  @include mq($until: extra-wide) {
    padding: 0 rem(15px);
    position: relative;
  }

  .web-technologies__title {
    text-align: center;

    @include mq($until: tablet) {
      > h2 {
        font-size: rem(35px);
      }
    }
  }

  .web-technologies__content {
    display: flex;
    justify-content: space-between;
    margin-top: rem(99px);

    @include mq($until: desktop) {
      background: none;
    }
  }

  .web-technologies__text {
    display: flex;
    flex-direction: column;
    max-width: rem(480px);
    width: 100%;
    position: relative;

    @include mq($until: extra-wide) {
      margin: 0 auto;
      z-index: 3;
    }
  }

  .web-technologies__img {
    display: flex;

    @include mq($until: wide) {
      position: absolute;
      top: 550px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include mq($until: desktop) {
      display: none;
    }

    @include mq($until: desktop) {
      display: none;
    }
  }
}
