.rc-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  color: $font-3;

  &.rc-dropdown-has-options .rc-dropdown-label {
    cursor: pointer;
  }

  &.rc-dropdown-loading .rc-dropdown-label {
    cursor: not-allowed;
    color: #b2b8ce;
  }

  .rc-dropdown-label {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    & svg:first-child:not(:last-child) {
      width: rem(16px);
      height: rem(16px);
    }
  }

  .rc-dropdown-options {
    position: absolute;
    display: flex;
    flex-direction: revert;
    width: auto;
    min-width: 100%;
    top: rem(30px);
    background: $white;
    box-shadow: 0px 32px 32px rgba(81, 113, 228, 0.15);
    z-index: 99;
    border: none;

    &-left {
      left: rem(-10px);
    }

    &-right {
      left: rem(-190px);

      @include mq($until: iphone-11) {
        left: rem(-194px) !important;
      }
    }

    &.middle {
      &:not(.hide) {
        left: rem(-230px);

        @include mq($until: iphone-11) {
          left: rem(-90px);
        }
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: rem(214px);

      @include mq($until: iphone-11) {
        min-width: 50%;
      }

      &:not(:first-child) {
        border-left: 1px solid $grey-100;
      }
    }

    &-item {
      position: relative;
      padding: rem(7px 5px 7px 16px);
      margin-top: rem(8px);
      color: $font-3;
      z-index: 2;

      &.disabled,
      &.link-disabled {
        color: #b8bdd3;
      }

      & label {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: rem(8px);
      }

      .gdb-icon-arrow-down {
        position: absolute;
        top: 50%;
        right: rem(18px);
        transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
        font-size: rem(9px) !important;
        width: auto;

        path {
          stroke-width: rem(2.5px);
        }
      }

      &.rc-dropdown-options-item-open {
        background: rgba($main-500, 0.1);
        color: $main-700;

        .gdb-icon-arrow-down {
          transform: translate3d(0, -50%, 0) rotate(90deg) !important;
        }
      }
    }

    a {
      @include link-styling;
    }
  }

  .gdb-icon {
    width: rem(8px);
    margin-left: rem(5px);
    fill: none;
    transition: 300ms transform;
  }

  &-open .gdb-icon {
    transform: rotate(180deg);
  }
}

.header__menu {
  .rc-dropdown-label {
    padding: rem(38.56px 0);
  }
}
