.our-mission {
  margin-top: rem(145px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include mq($until: iphone-11) {
    align-items: initial;
    margin-top: rem(56px);
  }

  &__title {
    @include mq($until: iphone-11) {
      padding: rem(0 20px);
    }

    h2 {
      max-width: rem(384px);
      margin: rem(0 0 16px);
      font-weight: 700;
      color: $font-4;
      font-size: rem(48px);
      line-height: rem(58.32px);

      @include mq($until: iphone-11) {
        margin: rem(0 0 12px);
        font-size: rem(32px);
        line-height: rem(36px);
        text-align: start;
      }
    }

    p {
      max-width: rem(384px);
      margin: rem(0 0 40px 0);
      color: $font-3;
      font-size: rem(24px);
      line-height: rem(32.64px);
      font-weight: 500;

      @include mq($until: iphone-11) {
        font-size: rem(16px);
        line-height: rem(20px);
        text-align: start;
      }
    }
  }
  &__description-block {
    max-width: rem(1008px);
    background-color: $white-secondary;

    p {
      margin: 0;
      max-width: rem(912px);
      padding: rem(40px);
      color: $font-3;
      font-size: rem(40px);
      line-height: rem(54.4px);
      font-weight: 700;
      letter-spacing: 0.01em;
      text-align: left;

      span {
        color: $main-500;
      }

      @include mq($until: small-desktop) {
        font-size: rem(25px);
        padding: rem(25px);
        text-align: center;
      }

      @include mq($until: iphone-11) {
        text-align: start;
        font-size: rem(16px);
        line-height: rem(24px);
      }
    }
  }
}
