.rc-dropdown {
  @include mq($until: small-desktop) {
    cursor: auto;
  }

  &.rc-dropdown-mega {
    position: unset;

    &-label {
      cursor: pointer;
      padding: rem(38.56px 0);
    }

    .rc-dropdown-options {
      left: 0;
      top: rem(102px) !important;
      width: 100%;

      &.hide {
        display: none;
      }
    }

    .rc-dropdown-options-row {
      &:first-child {
        position: relative;
        width: rem(192px);
        margin-left: rem(75px);
        margin-right: rem(15px);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0% 110%;

        &.intersected {
          background-image: url('/storage/svg/decoration/decoration.svg');
        }

        @include mq($until: small-desktop) {
          position: absolute;
          padding-top: rem(80px);
          top: rem(-105px);
          right: 0px;
          margin: 0;
          width: rem(320px);
          height: 100vh;
          background: $white;
        }

        .gdb-shapes {
          position: absolute;
          bottom: rem(24px);
          height: calc(100% - 180px);
          display: flex;
          align-items: flex-start;

          .gdb-icon-shape {
            margin: 0.5rem 0 0 0.5rem;
            height: 100%;
            width: auto;
            max-width: calc(100% - 24px);
            transform: rotate(0deg);

            &:last-child {
              width: 40%;
              bottom: auto;
              height: auto;
              position: absolute;
              left: 25%;
              top: 10%;
              transform: translate3d(0, -50%, 0);

              @include mq($until: small-desktop) {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }

      .rc-flex-row {
        padding: rem(16px 72px 32px);
      }

      .rc-flex-item {
        width: 25%;
      }
    }
  }

  &:not(.rc-dropdown-open) .rc-dropdown-options {
    display: none;
  }

  .rc-dropdown-options-item {
    cursor: pointer;
    display: block;
    border-radius: rem(2px);

    &.loading,
    &.disabled {
      background-color: $grey-200;
      color: $grey-500;
      cursor: not-allowed;
    }

    .lds-ring {
      position: absolute;
      left: inherit;
      right: rem(15px);
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      margin: 0;

      div {
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border-width: rem(2px);
      }
    }
  }
}

.buttons-row {
  display: flex;
  justify-content: center;
  margin-bottom: rem(30px);

  > button {
    font-size: rem(12px);
    max-width: rem(100px);
    width: 100%;
    margin-left: rem(10px);
    height: rem(40px);
  }
}

.close-btn-label {
  position: absolute;
  top: rem(15px);
  right: rem(25px);
  z-index: 10000;
  cursor: pointer;

  svg:nth-child(1) {
    width: rem(20px);
    height: rem(30px);

    path {
      fill: $font-3;
    }
  }
}
