@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-block {
  @include flex;
  margin-top: rem(152px);
  position: relative;

  @include mq($until: iphone-11) {
    display: block;
    margin-top: rem(48px);
    justify-content: flex-start;
  }

  .__lead-generation {
    h5 {
      max-width: rem(592px) !important;
      font-weight: 500 !important;
    }
  }

  .__customers {
    h5 {
      max-width: rem(861px);

      @include mq($until: desktop) {
        padding: 0 10px;
      }

      @include mq($until: small-tablet) {
        font-size: rem(20px);
      }

      @include mq($until: iphone-11) {
        padding: 0;
      }
    }
  }

  .__careers {
    h5 {
      max-width: rem(800px);
      color: $font-3;
      margin-top: rem(12px);
    }
  }

  .info-block__wrapper {
    @include flex;
    flex-direction: column;
    text-align: center;
    z-index: 10;
    background-color: $white;

    &.transparent {
      background-color: transparent;
    }

    a {
      margin-top: rem(34px);

      @include mq($until: iphone-11) {
        width: 100%;
        margin-top: rem(12px);
      }

      .button {
        margin-top: 0;

        @include mq($until: iphone-11) {
          width: 100%;
        }
      }
    }

    .input-button {
      position: relative;
      margin-top: rem(34px);
      max-width: rem(585px);
      width: 100%;
      display: flex;

      button {
        text-wrap: nowrap;
        margin-top: 0;
        text-wrap: nowrap;

        @include mq($until: tablet) {
          margin-top: rem(20px);
        }
      }

      .input-wrapper {
        margin: 0;

        @include mq($until: iphone-11) {
          max-width: 100%;
          width: 100%;
          align-self: flex-start;

          input {
            width: 100%;
          }
        }
      }

      @include mq($until: tablet) {
        flex-direction: column;
        align-items: center;
      }

      @include mq($until: iphone-11) {
        max-width: 100%;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }

    .api-buttons {
      margin-top: rem(34px);

      @include mq($until: iphone-11) {
        width: 100%;
      }

      > * {
        @include mq($until: tablet) {
          display: flex;
        }

        &:first-child {
          @include mq($until: tablet) {
            margin-top: 0;
          }
        }

        &:not(:first-child) {
          @include mq($until: tablet) {
            margin-top: rem(20px);
          }
        }

        &:not(:last-child) {
          margin-right: rem(32px);

          @include mq($until: tablet) {
            margin-right: 0;
          }
        }
      }
    }

    .input-error-label {
      position: absolute;
      color: $danger;
      right: rem(250px);
      bottom: rem(-5px);
      text-align: right;

      @include mq($until: tablet) {
        bottom: rem(65px);
        right: unset;
        display: block;
        text-align: center;
      }
    }
  }

  button {
    margin-top: rem(20px);
  }

  h1 {
    margin: rem(16px 16px 12px);
    max-width: rem(800px);
    width: 100%;
    color: $font-4;

    @include mq($until: tablet) {
      font-size: rem(48px);
      line-height: rem(67px);
    }

    @include mq($until: iphone-11) {
      margin: rem(16px 0 12px);
      align-self: flex-start;
      font-size: rem(40px);
      line-height: rem(44px);
    }
  }

  h2 {
    margin: 0;
    max-width: rem(592px);
    width: 100%;
    color: $font-3;
    font-size: rem(24px);
    font-weight: 500;
    line-height: rem(29px);

    @include mq($until: small-tablet) {
      font-size: rem(20px);
    }

    @include mq($until: iphone-11) {
      align-self: flex-start;
      font-size: rem(16px);
      line-height: rem(20px);
    }
  }

  > div {
    @include mq($until: 321px) {
      input {
        max-width: 90%;
      }
    }
  }
}
