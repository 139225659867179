.articles-simple__social-media {
  display: flex;
  margin-top: rem(40px);
  flex-direction: column;

  .media-icons {
    display: flex;
    gap: rem(12px);
    margin-top: rem(20px);

    a {
      display: block;
      width: rem(50px);
      height: rem(50px);

      &:focus {
        @include focus;
      }
    }

    .article-fb-logo,
    .article-tw-icon,
    .article-in-logo {
      fill: none;
      font-size: rem(48px);
      background: $white;
    }
  }

  span {
    display: block;
    font-size: rem(14px);
    line-height: rem(12px);
    color: $font-3;
    text-transform: uppercase;
  }
}
