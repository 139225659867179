.presentation {
  position: relative;
  width: 100%;
  max-height: 100%;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  height: rem(630px);
  max-width: rem(1144px);

  @include mq($until: small-desktop) {
    max-height: rem(512px);
  }

  @include mq($until: tablet) {
    max-height: rem(446px);
  }

  @include mq($until: small-tablet) {
    max-height: rem(304px);
  }

  @include mq($until: iphone-x) {
    max-height: rem(240px);
  }

  @include mq($until: iphone-se) {
    max-height: rem(192px);
  }

  &.presentation-type-text-icon {
    .button {
      padding-left: rem(24px);
      padding-right: rem(24px);
      height: rem(54px);
    }
  }

  &.presentation-type-icon {
    .button {
      width: 100%;
      height: rem(64px);
      max-width: fit-content;
      min-width: inherit;
      border-radius: rem(2px);
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: rem(22px);
        vertical-align: middle;
        padding-left: rem(5px);
      }
    }
  }

  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: inherit;
    min-width: inherit;
    box-shadow: 0px 24px 24px rgba(7, 123, 231, 0.24);
  }

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
