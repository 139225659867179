.our-values {
  &__color {
    width: rem(72px);
    height: rem(72px);

    > div {
      width: rem(24px);
      height: rem(24px);
    }
  }

  span:nth-child(1) {
    span {
      max-width: rem(488px);
    }
  }
}

.first-element {
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-bottom-left-radius: 50%;

  > div {
    background-color: $main-500;
    border-top-right-radius: 50%;
    @include delayed-box-shadow-animation(0px, 16px, 16px, rgba(12, 37, 127, 0.08), 5s);
  }
}

.second-element {
  background-color: #07e796;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-top-right-radius: 50%;

  > div {
    background-color: $white-secondary;
    border-bottom-left-radius: 50%;
    @include delayed-box-shadow-animation(0px, 16px, 16px, rgba(12, 37, 127, 0.08), 7s);
  }
}

.third-element {
  background-color: $main-500;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-bottom-left-radius: 50%;

  > div {
    background-color: $white-secondary;
    border-top-right-radius: 50%;
    @include delayed-box-shadow-animation(0px, 16px, 16px, rgba(12, 37, 127, 0.08), 9s);
  }
}

.fourth-element {
  background-color: #f62942;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-top-left-radius: 50%;

  > div {
    background-color: $white-secondary;
    border-bottom-right-radius: 50%;
    @include delayed-box-shadow-animation(0px, 16px, 16px, rgba(12, 37, 127, 0.08), 9s);
  }
}

.fifth-element {
  background-color: #ffb84f;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 50%;

  > div {
    background-color: $white-secondary;
    border-top-left-radius: 50%;
    @include delayed-box-shadow-animation(0px, 16px, 16px, rgba(12, 37, 127, 0.08), 11s);
  }
}
