.footer-upper {
  padding: rem(128px) 0;
  max-width: rem($layout-base-2);
  margin: 0 auto;
  text-align: center;
  color: $white;

  @include mq($until: small-tablet) {
    padding: rem(50px 20px);
  }

  h3 {
    margin: 0;

    @include mq($until: tablet) {
      font-size: rem(35px);
    }

    @include mq($until: small-tablet) {
      font-size: rem(28px);
      line-height: rem(32px);
    }

    @include mq($until: iphone-11) {
      width: 100%;
      text-align: left;
    }
  }

  .button {
    margin-top: rem(48px);
  }
}

.footer-upper-loader {
  max-width: rem($layout-base-2);
  margin: 0 auto;
  background-color: $secondary-500;
  height: rem(417px);
}
