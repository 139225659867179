.loading-link {
  &.button {
    background: transparent !important;
    height: auto !important;
    color: $font-4 !important;
    font-size: rem(16px) !important;
    font-weight: 100;
    padding: 0;

    &.loading,
    &.button-type-primary.loading {
      background: transparent !important;
      color: $font-2 !important;
    }

    .lds-ring {
      top: 56% !important;
      right: rem(-80px) !important;
      width: rem(14px) !important;
      height: rem(14px) !important;

      div {
        width: rem(14px) !important;
        height: rem(14px) !important;
        border-color: $font-2 transparent transparent transparent !important;
      }
    }
  }
}
