.web-tech-widget {
  .web-tech-widget__content {
    display: flex;
    justify-content: space-between;
    border-bottom: rem(1px) solid $grey-100;
    margin-top: rem(36px);
    padding-bottom: rem(16px);

    &.has-children {
      cursor: pointer;
    }

    &.has-no-children .web-tech-widget__title {
      h4,
      span {
        color: $font-2;
      }
    }

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .web-tech-widget__title {
    display: flex;

    > span {
      color: $main-500;
      font-size: rem($text-body-font-2);
      line-height: rem($text-body-height-2);
    }
  }

  .web-tech-widget__wrapper-active {
    position: absolute;
    z-index: 100;
    width: calc(100% + 10px);
    height: 100%;
    top: 0;
    left: -10px;
    background: $white;

    svg {
      font-size: rem(24px) !important;
      height: rem(24px);
      fill: none;
    }
  }

  svg {
    font-size: rem(10px);
    height: rem(18px);
    fill: none;
  }
}
