.burger-menu-item {
  position: relative;
  padding: rem(12px 21px);
  cursor: pointer;
  transition: 0.3s;
  color: $font-4;
  font-size: rem(16px);

  &:hover,
  &.active {
    background: $white-secondary;
    color: $font-3;
  }

  &.loading {
    color: $font-2;

    .burger-menu-arrow path {
      stroke: $font-2 !important;
    }
  }
}

.burger-menu-item-children {
  display: none;

  .burger-menu-item-child {
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: rem(8px 20px 8px 30px);
    font-size: rem(15px);
    color: $font-4;

    &:hover,
    &.active {
      background: rgba(7, 123, 231, 0.05);
      color: $font-3;
    }

    .gdb-api-rectangle path,
    .gdb-business-rectangle path,
    .gdb-tender-rectangle path,
    .gdb-sales-rectangle path,
    .gdb-sales-engagement-rectangles path,
    .gdb-outreach path {
      fill: $main-500;
    }

    .gdb-icon-database path {
      fill: none;
    }

    @include mq($until: small-tablet) {
      max-width: rem(349.76px);
    }

    @include mq($until: 321px) {
      max-width: rem(313.6px);
    }
  }

  .burger-menu-item {
    display: block;
    font-size: rem(15px);
    padding: rem(8px 20px 8px 30px);
    color: $font-4;

    &:hover,
    &.active {
      background: rgba(7, 123, 231, 0.05);
      color: $font-3;
    }

    &.loading {
      color: $font-2;

      .burger-menu-arrow path {
        stroke: $font-2;
      }
    }
  }

  @include mq($until: small-tablet) {
    max-height: fit-content;
  }

  &.show {
    display: block;
  }
}

.burger-menu-item.active .burger-menu-arrow,
.burger-menu-item-child.active .burger-menu-arrow {
  transform: translateY(-50%) rotate(180deg);
}

.burger-menu-arrow {
  position: absolute;
  right: rem(20px);
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(12px);
  transition: all 0.3s;
}
