.people-feedback__loader {
  @include skeleton;
  height: rem(685px);
  margin-top: rem(175px);

  @include mq($until: desktop) {
    height: rem(745px);
    margin: rem(175px 0);
  }
}

.people-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__bg {
    background: $white-secondary;
    padding-top: rem(372px);
    margin-top: rem(-180px);
    margin-bottom: rem(-192px);
    padding-bottom: rem(196px);
  }

  &__image {
    width: rem(64px);
    height: rem(64px);
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: contain;
    }
  }

  > div:nth-child(1) {
    h2 {
      font-size: rem(35px);
      color: $font-4;
      max-width: rem(592px);
      width: 100%;
    }
  }

  &__container {
    max-width: rem($layout-base-1);
    width: 100%;
    margin-top: rem(64px);

    @include mq($until: wide) {
      padding-left: rem(10px);
      padding-right: rem(10px);
    }

    @include mq($until: desktop) {
      display: block;
    }

    .skeleton {
      width: 100%;
      height: rem(548px);
      @include skeleton;

      @include mq($until: desktop) {
        height: rem(478px);
      }
    }
  }

  &__wrapper {
    max-width: rem(384px);
    width: 100%;
    height: rem(484px);
    background: $white;
    border-radius: rem(2px);
    padding: rem(64px) rem(40px);

    &:not(:last-child) {
      margin-right: rem(32px);
    }

    @include mq($until: desktop) {
      padding: rem(40px) rem(35px);
      width: 100%;
      min-width: rem(370px);
    }

    @include mq($until: iphone-11) {
      max-width: rem(280px);
    }
  }

  &__quotes {
    display: flex;
    align-items: center;

    .icons:nth-child(1) {
      margin-left: rem(20px);
      z-index: 3;
    }

    svg {
      margin-left: rem(2px);
    }

    > svg:nth-child(1) {
      font-size: rem(40px);
      height: rem(32px);
      fill: none;
      z-index: 1;
    }

    > svg:nth-child(2) {
      margin-left: rem(-20px);
      font-size: rem(80px);
      height: rem(80px);
      fill: none;
    }
  }

  h2 {
    margin: 0;
    max-width: rem(592px);
    width: 100%;
    text-align: center;

    @include mq($until: desktop) {
      text-align: center;
    }

    @include mq($until: tablet) {
      padding: rem(0px) rem(15px);
    }

    @include mq($until: iphone-11) {
      text-align: start;
      font-size: rem(32px);
      line-height: rem(36px);
    }
  }

  &__text {
    margin-top: rem(40px);
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    color: $font-3;
  }

  &__people {
    display: flex;
    margin-top: rem(32px);
  }

  h2 {
    margin: 0;
    color: $font-4;
    font-size: rem(24px) !important;
  }

  &__info {
    display: flex;
    align-items: center;
    color: $font-2;
    margin-left: rem(8px);
  }

  @include mq($until: tablet) {
    margin-bottom: rem(-48px);
  }

  @include mq($until: iphone-11) {
    padding-top: rem(186px);
  }
}
