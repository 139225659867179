.gdb-icon {
  &.gdb-icon-size {
    &-small {
      font-size: rem(14px);
    }

    &-big {
      font-size: rem(45px);
    }
  }

  &.disabled {
    cursor: not-allowed;

    path {
      fill: #ccc;
    }
  }

  &-half-star {
    height: rem(24px) !important;
  }

  &-about-us-upper {
    font-size: rem(280px) !important;
    height: rem(340px);
    fill: none;
  }
}
