.benefits-blog {
  display: flex;
  justify-content: center;
  grid-column-gap: rem(32px);

  @include mq($until: desktop) {
    flex-direction: column;
  }

  .benefits-blog-left {
    border: 1px solid $grey-100;
    padding: rem(15px);
    background: $grey-000;
    border-radius: rem(2px);
    max-width: rem(630px);
    width: 100%;

    @include mq($until: desktop) {
      max-width: 100%;
    }

    .benefits-blog-link {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: rem(400px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: rem(32px);
      padding-bottom: rem(32px);
      padding-right: rem(32px);
      color: $white;
      position: relative;
      z-index: 5;

      &:focus {
        @include focus;
      }

      @include mq($until: wide) {
        max-width: 100%;
        padding-left: rem(15px);
        padding-right: rem(15px);
      }

      .benefits-blog-left-loading {
        position: absolute;
        left: rem(25px);
        top: rem(25px);
      }

      .benefits-blog-left-type {
        display: flex;
        justify-content: space-between;

        span {
          font-size: rem(12px);
          letter-spacing: 0.08em;
          text-transform: uppercase;
        }
      }

      .benefits-blog-description {
        max-width: rem(400px);
        width: 100%;
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        > * {
          margin: 0;
        }

        @include mq($until: small-tablet) {
          max-width: rem(440px);
        }

        @include mq($until: 375px) {
          max-width: rem(400px);
        }

        @include mq($until: 321px) {
          max-width: rem(328px);
        }
      }

      .benefits-blog-left-title {
        font-size: rem(24px);
        margin: rem(10px) 0;
        max-width: rem(408px);
        color: $white;
        text-align: left;
        line-height: rem(29px);
        width: 100%;

        @include mq($until: small-tablet) {
          max-width: rem(440px);
        }

        @include mq($until: 375px) {
          max-width: rem(400px);
        }

        @include mq($until: 321px) {
          max-width: rem(328px);
        }

        &.loading {
          color: $font-2;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0e1b2f;
        opacity: 0.5;
        z-index: -1;
      }
    }
  }

  .benefits-blog-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row-gap: rem(15px);

    > a {
      position: relative;
      display: flex;
      max-width: rem(516px);
      width: 100%;

      border: 1px solid $grey-100;
      padding: rem(15px);
      background: $grey-000;
      border-radius: rem(2px);

      &:focus {
        @include focus;
      }

      @include mq($until: wide) {
        max-width: 100%;
      }

      > span:first-child {
        max-width: rem(176px) !important;
        width: 100%;
      }
    }

    a:first-child {
      margin-top: 0;

      @include mq($until: desktop) {
        margin-top: rem(20px);
      }
    }

    img {
      margin-right: rem(32px);
      object-fit: contain !important;
      max-height: rem(132px);
      max-width: rem(176px);
      width: 100%;
      height: 100%;

      @include mq($until: small-tablet) {
        margin-right: rem(10px);
      }
    }
  }

  .benefits-blog-loading {
    position: absolute;
    left: rem(77.5px);
    top: rem(65px);
  }

  .benefits-blog-dep {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(15px);

    .benefits-blog-type {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    span {
      margin: rem(10px) 0 0;
      color: $font-3;
      font-weight: 400;
      font-size: 0.75rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }

    h2 {
      font-size: rem(24px);
      font-weight: 700;
      line-height: rem(29px);
      text-align: left;
      margin: rem(10px) 0 0;
      color: $font-4;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mq($until: small-tablet) {
        font-size: rem($text-body-font-2);
        line-height: rem($text-body-height-2);
      }

      &.loading {
        color: $font-2;
      }
    }
  }

  a {
    @include link-styling;
  }
}
