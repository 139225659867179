.thank-you__video {
  position: relative;
  max-height: rem(484px);

  img {
    vertical-align: middle;
    max-width: rem(728px);
    width: 100%;
    height: 100%;
    max-height: rem(484px);
  }

  iframe {
    max-width: rem(728px);
    width: rem(728px);
    min-height: rem(484px);
    vertical-align: middle;
    border: none;

    @include mq($until: tablet) {
      width: 100%;
      height: 100%;
      min-height: auto;
    }
  }

  .play-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    .thank-you-button {
      display: flex;
      align-items: center;
      padding: rem(26px);
      box-shadow: rem(0px) rem(24px) rem(24px) rgba(7, 123, 231, 0.24);
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      .icon {
        margin: 0 rem(10px) 0 0;
      }

      svg {
        font-size: rem(15px);
        height: rem(16px);

        path {
          color: $white;
        }
      }
    }
  }
}
