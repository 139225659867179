.sales-force {
  position: relative;

  @include mq($until: extra-wide) {
    padding: 0 rem(15px);
  }

  .get-data {
    &__info {
      .get-data__text {
        padding: rem(0px);
        margin-bottom: rem(40px);
      }

      .get-data__button {
        display: none;
      }
    }
  }

  .info-block__wrapper {
    background: none;

    h1 {
      max-width: rem(900px);
      width: 100%;
      z-index: 12;
    }

    h5 {
      font-weight: 400;
    }

    > a button {
      display: none;
    }
  }

  .circle-content {
    height: rem(176px);
    width: rem(176px);
    margin: rem(50px) auto rem(250px);
    z-index: 8;

    @include mq($until: tablet) {
      top: rem(600px);
    }

    @include mq($until: iphone-11) {
      margin: rem(32px) auto rem(56px);
    }

    .circle-content__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .center-image {
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: rem(2px);
      }
    }
  }

  .center-image {
    background-color: $white;
    box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    align-self: center;
  }
}

.sales-force-content {
  background-position: center center;

  &.intersected {
    background: url('/storage/svg/categories/sales.svg') no-repeat;
  }
}
