@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: rem(50px);
  height: rem(50px);

  &.lds-ring-small {
    width: rem(32.5px);
    height: rem(32.5px);

    div {
      width: rem(30px);
      height: rem(30px);
      border-width: rem(3px);
    }
  }

  &.lds-ring-color {
    &-white {
      div {
        border-color: $white transparent transparent transparent;
      }
    }
  }

  div {
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: rem(50px);
    height: rem(50px);
    border: rem(5px) solid $main-500;
    border-radius: 50%;
    animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $main-500 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
