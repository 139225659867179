.form-book {
  &.country-promotion {
    background-color: $grey-000;
  }

  form {
    border: 1px solid $grey-100;
    box-sizing: border-box;
    border-radius: rem(2px);
    padding: rem(52px);
    max-width: rem(488px);
    width: 100%;

    @include mq($until: small-tablet) {
      padding: rem(0);
      border: none;
    }

    > * {
      &:not(:first-child) {
        margin-top: rem(32px);
        margin-bottom: 0;
      }
    }

    button {
      max-width: 100%;
      width: 100%;
    }

    textarea {
      width: rem(384px);
      height: rem(128px);
      resize: none;
      font-family: $font;
    }

    .sub-button-text {
      text-align: left;
      margin-top: rem(24px);
      font-size: rem(16px);
      color: $font-3;

      a {
        color: $font-3;

        &:hover {
          opacity: 0.8;
        }

        &:focus {
          @include focus;
        }
      }
    }

    span {
      @include mq($until: small-desktop) {
        left: rem(18px);
      }
    }
  }
}
