.contact-us {
  &__social-media {
    text-align: left;
    position: relative;
    z-index: 10;

    @include mq($until: tablet) {
      text-align: center;
    }
  }

  &__media {
    margin-top: rem(30px);
    display: flex;
    align-items: center;

    @include mq($until: tablet) {
      justify-content: center;
    }

    a {
      width: rem(44px);
      height: rem(44px);
      background: $white;
      border: 1px solid $main-500;
      box-sizing: border-box;
      border-radius: rem(2px);
      margin-right: rem(22px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-twitter {
      font-size: rem(18px);
      height: rem(13px);
      fill: none;
    }
    .icon-facebook {
      font-size: rem(8px);
      height: rem(14px);
      fill: none;
    }
    .icon-linkedin {
      font-size: rem(16px);
      height: rem(16px);
      fill: none;
    }
    .icon-medium {
      font-size: rem(16px);
      height: rem(13px);

      path {
        fill: $main-500;
      }
    }
  }
}
