.info-block-promotion {
  max-width: rem(592px);

  h2 {
    color: $font-4;
    font-size: rem(48px);
    line-height: rem(56px);
  }

  .description {
    color: $font-3;
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(24px);

    &:not(:last-child) {
      margin-bottom: rem(8px);
    }

    .icon-check {
      margin-right: rem(6px);
      fill: none;
    }

    span {
      vertical-align: text-bottom;
    }
  }
}
