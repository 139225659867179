.policy-layout-block {
  margin-top: rem(152px);

  .policy-layout__title {
    h1 {
      max-width: rem(800px);
      font-weight: 700;
      font-size: rem(56px);
      color: $font-4;
      text-align: center;
      margin: 0 auto;
    }
  }
}
