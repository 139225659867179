.get-data {
  max-width: rem($layout-base-2);
  width: 100%;
  margin: rem(75px) auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(25px);

  @include mq($until: tablet) {
    flex-direction: column;
    gap: 0;
  }

  @include mq($until: iphone-11) {
    margin: rem(72px) auto 0;
  }

  &.loading .get-data__title,
  &.loading .get-data__sub_title,
  &.loading .get-data__button,
  &.loading .get-data__text {
    @include skeleton-text;
  }

  &__product {
    display: flex;
    color: $secondary-400;
    font-size: rem(18px);
    margin-bottom: rem(8px);
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $secondary-500;
      text-decoration: none;
    }
  }

  .get-data__info {
    max-width: rem(488px);
    width: 100%;
  }

  .get-data__title,
  .get-data__product {
    @include mq($until: tablet) {
      text-align: center;
      width: 100%;
      display: block;
    }
  }

  .get-data__sub_title {
    margin-top: rem(10px);
    color: $font-4;
    font-size: rem(24px);
    line-height: rem(32px);
    font-weight: 500;
  }

  h2 {
    margin: 0;
    color: $font-4;
    font-size: rem(32px);
    line-height: rem(38px);

    @include mq($until: tablet) {
      margin: 0 auto;
      font-size: rem(27px);
      line-height: rem(30px);
    }

    @include mq($until: iphone-11) {
      text-align: start;
      font-size: rem(24px);
      line-height: rem(28px);
    }
  }

  .get-data__text {
    margin-top: rem(16px);
    font-style: normal;
    font-weight: 400;
    color: $font-3;
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);

    @include mq($until: tablet) {
      text-align: center;
    }

    @include mq($until: iphone-11) {
      margin-top: rem(8px);
      text-align: start;
      font-size: rem(16px);
      line-height: rem(24px);
    }
  }

  .get-data__button {
    display: inline-flex;
    margin-top: rem(16px);

    .button {
      padding: rem(15px) rem(20px);
    }

    @include mq($until: tablet) {
      text-align: center;
    }

    @include mq($until: iphone-11) {
      margin-top: rem(8px);
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  .get-data__image,
  .get-data__video {
    min-height: rem(488px);
  }

  .get-data__video video {
    max-width: 100%;
  }

  .get-data__image,
  .get-data__image-placeholder {
    margin-left: rem(32px);
    max-width: rem(488px);
    width: 100%;

    @include mq($until: small-tablet) {
      margin-left: 0 !important;
    }

    img {
      object-fit: contain;
    }
  }

  .get-data__image {
    @include mq($until: small-tablet) {
      margin-left: 0;
    }
  }

  .get-data__video {
    @include mq($until: desktop) {
      margin-right: 0;

      video {
        margin: 0 auto;
        width: 100%;
      }
    }

    @include mq($until: tablet) {
      margin-top: rem(32px);
    }
  }

  .get-data__image-placeholder {
    min-height: rem(488px);
    max-width: rem(488px);
    width: 100%;

    @include skeleton;
  }
}

.get-data-reverse {
  flex-direction: row-reverse;
  margin-bottom: rem(75px);
  margin-top: rem(75px);

  @include mq($until: wide) {
    margin-bottom: rem(50px);
  }

  @include mq($until: tablet) {
    flex-direction: column;
  }

  @include mq($until: iphone-11) {
    margin-top: rem(8px);
    margin-bottom: rem(8px);
  }

  .get-data__image,
  .get-data__video,
  .get-data__image-placeholder {
    margin-left: 0;
    max-width: rem(488px);
    width: 100%;

    @include mq($until: desktop) {
      video {
        margin: 0 auto;
        width: 100%;
      }
    }
  }

  .get-data__info {
    max-width: rem(488px);
    width: 100%;
  }
}
