.content {
  &__wrapper {
    height: rem(488px);
    max-width: rem(1190px);
    width: 100%;
    margin: 0 auto;
    padding: 0 rem(15px);
    display: flex;
    align-items: center;

    @include mq($until: wide) {
      margin: 0 auto;
    }
    @include mq($until: desktop) {
      height: auto;
    }
    @include mq($until: small-desktop) {
      flex-direction: column;
    }
    @include mq($until: iphone-11) {
      padding: 0;
    }

    > div {
      color: $font-3;
      max-width: rem(592px);
      width: 100%;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      &:first-child {
        padding-right: rem(40px);

        @include mq($until: small-desktop) {
          padding-right: 0;
        }
      }

      @include mq($until: wide) {
        text-align: center;
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
        padding-top: rem(50px);
      }

      @include mq($from: wide) {
        margin-left: rem(25px);
      }

      @include mq($until: iphone-11) {
        p {
          text-align: start;
        }
      }
    }

    .laptop-svg {
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .alternative {
    @include mq($until: wide) {
      margin: 0;
    }
  }

  h2 {
    margin: 0 0 rem(16px);
    color: $font-4;
    max-width: rem(489px);
    width: 100%;

    @include mq($until: small-tablet) {
      font-size: rem(35px);
    }

    @include mq($until: iphone-11) {
      font-size: rem(32px);
      line-height: rem(36px);
      text-align: start !important;
      margin-bottom: rem(12px);
    }
  }
}
