.video-container-wrapper {
  padding: rem(25px 0);
  margin: rem(192px) auto;

  @include mq($until: iphone-11) {
    margin: rem(56px) auto;
  }

  // medium size (default)
  &.video-container-md {
    h3 {
      font-size: rem(40px);
      line-height: rem(48.6px);
    }

    .video-container__info {
      max-width: rem(380.8px);
    }
  }

  // large size
  &.video-container-lg {
    h3 {
      font-size: rem(56px);
      line-height: rem(64px);
    }

    .video-container__info {
      max-width: rem(680px);
    }
  }

  .video-container {
    display: flex;
    max-width: rem($layout-base-1);
    width: 100%;
    margin: 0 auto;

    @include mq($until: wide) {
      flex-direction: column;
      align-items: center;
      margin: rem(96px) auto;
    }

    @include mq($until: iphone-11) {
      margin: rem(56px) auto;
    }

    h3 {
      margin: 0 0 rem(16px);
      color: $font-4;
      font-weight: 700;

      @include mq($until: wide) {
        margin: 0 auto rem(16px);
        text-align: center;
      }

      @include mq($until: desktop) {
        font-size: rem(35px);
      }
    }

    span {
      max-width: rem(380.8px);
      font-size: rem(20px);
      margin-top: rem(16px);
      color: $font-3;
      font-weight: 500;

      @include mq($until: wide) {
        margin: 0 auto rem(16px);
        text-align: center;
      }
    }

    .video-container__info {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto rem(32px) auto 0;

      @include mq($until: wide) {
        margin: rem(0 auto 60px);
        text-align: center;

        h3 {
          max-width: rem(680px);
        }
      }

      @include mq($until: desktop) {
        h3 {
          line-height: rem(40px);
          max-width: rem(450px);
        }
      }

      @include mq($until: iphone-11) {
        margin-bottom: rem(25px);
        padding: rem(0 20px);

        h3 {
          font-size: rem(24px);
          line-height: rem(28px);
          max-width: 100%;
          text-align: start;
          margin-bottom: rem(12px);
        }

        span {
          font-size: rem(16px);
          line-height: rem(20px);
          margin: 0 0 rem(16px);
          text-align: start;
        }
      }

      .video-container__advantages {
        display: flex;
        flex-wrap: wrap;

        .video-container__inner {
          position: relative;

          svg {
            position: absolute;
            left: rem(3px);
            top: rem(5px);
          }

          span {
            padding-left: rem(24px);
            margin: rem(0 15px 12px 0);
            font-size: rem(16px);
            line-height: rem(24px);
            font-weight: 400;
            color: $font-3;
          }
        }

        @include mq($until: wide) {
          max-width: rem(500px);
          margin: 0 auto;
          justify-content: center;

          h3 {
            max-width: rem(500px);
            line-height: rem(40px);
          }
        }

        @include mq($until: iphone-11) {
          justify-content: flex-start;
        }
      }

      .video-container__button {
        margin-top: rem(24px);

        @include mq($until: iphone-11) {
          margin-right: auto;
        }
      }
    }

    &__video {
      max-width: rem($layout-base-2);
      width: 100%;

      @include mq($until: wide) {
        margin-top: rem(40px);
      }
    }
  }
}
