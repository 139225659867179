.web-technologies {
  &__suggestions {
    display: flex;
    cursor: pointer;
    transition: 0.3s all ease;
    margin-top: rem(35px);
  }
  &__suggestions:nth-child(1) {
    margin-top: 0;
  }
  &__left {
    min-width: 24px;
    position: relative;
    display: flex;
    align-items: center;
    > span {
      position: absolute;
      bottom: 40%;
      left: 25%;
      font-size: rem(24px);
      color: $font-4;
      font-weight: 700;
      margin-bottom: rem(4px);
    }

    svg {
      font-size: rem(8px);
      height: rem(8px);
      fill: null;
    }
  }
  &__true {
    > span {
      transition: 0.3s all ease;
      color: $main-500;
    }
    svg {
      font-size: rem(24px) !important;
      height: rem(24px);
      fill: null;
    }
  }

  &__right {
    color: $font-3;
    margin-left: rem(39px);
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
  }

  h2 {
    margin: 0;
    color: $font-4;
  }
  svg {
    transition: 0.3s all ease;
  }
}
