.mexico.layout {
  .product-head {
    @include mq($until: wide) {
      margin-top: -121px;
    }

    @include mq($until: small-desktop) {
      margin-top: -100px;
    }

    @include mq($until: small-tablet) {
      height: auto;
      margin-top: -50px;

      .wrapper {
        left: inherit;
        transform: inherit;
      }
    }
  }

  .tachometers-ul {
    @include mq($until: desktop) {
      .tachometer-li {
        width: 33%;
      }
    }

    @include mq($until: small-desktop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      padding-top: 25px;

      .tachometer-li {
        width: 100% !important;
        margin: 25px;
        min-width: 225px;
        max-width: 225px;
      }
    }
  }

  .section-filters {
    @include mq($until: desktop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > div {
        width: 100% !important;
        margin: 10px;
        min-width: 90px;
        max-width: 110px;
      }
    }
  }

  .four-one {
    @include mq($until: small-desktop) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > * {
        min-width: 130px;
        max-width: 130px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        text-align: center;

        .zh-svg-container {
          width: 100%;

          svg {
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .four-one-content {
          flex-direction: column;
          width: 100%;
          border-right: inherit !important;
        }
      }
    }

    @include mq($until: small-tablet) {
      bottom: inherit !important;
    }
  }

  .banner-content-title-small {
    @include mq($until: tablet) {
      font-size: 20px !important;
      line-height: 22px !important;
    }
  }

  .buttons-group {
    @include mq($until: desktop) {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      text-align: center;

      > * {
        display: block;
        margin: 20px !important;
        width: 100%;
      }
    }
  }

  .view-mode {
    @include mq($until: small-desktop) {
      margin-top: 25px !important;
    }
  }

  .layout-main-container {
    @include mq($until: tablet) {
      margin-bottom: 190px;
    }
  }

  .benefits ul {
    @include mq($until: small-desktop) {
      display: flex;
      flex-wrap: wrap;

      > * {
        width: 50%;
      }

      p {
        @include mq($until: tablet) {
          font-size: rem(14px);
        }
      }
    }
  }
}
