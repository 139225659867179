.articles-years {
  padding: 0 rem(15px);

  @include mq($until: desktop) {
    padding: 0 rem(20px);
  }

  .articles-years__title {
    @include mq($until: small-tablet) {
      padding-bottom: rem(25px);
      padding-right: rem(10px);
    }

    .icons {
      margin-bottom: rem(0px);
    }
  }

  .media-icons {
    margin-top: rem(20px);

    @include mq($until: wide) {
      display: flex;
    }

    .icons {
      margin-bottom: rem(10px);

      @include mq($until: wide) {
        margin-right: rem(5px);
        margin-left: rem(5px);
      }
    }

    .icons:nth-child(1) {
      margin-top: rem(-10px);

      @include mq($until: wide) {
        margin-top: rem(0px);
      }
    }
  }

  &__content {
    display: flex;
    max-width: rem($layout-base-2);
    width: 100%;
    margin: 0 auto;

    @include mq($until: wide) {
      text-align: center;
      display: block;
    }
  }

  &__info {
    max-width: rem(696px);
    width: 100%;
    margin-left: (56px);

    @include mq($until: wide) {
      margin: 0 auto;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(12px);
    border-bottom: rem(1px) solid $grey-200;

    > span {
      font-weight: 700;
      font-size: rem(24px);
      color: $main-500;

      @include mq($until: wide) {
        padding-top: rem(30px);
        margin: 0 auto;
      }
    }

    .icons {
      margin-left: rem(10px);
      margin-bottom: rem(10px);
    }
  }

  &__text {
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    margin-top: rem(24px);
    color: $font-3;
  }

  &__capitalisation {
    margin-top: rem(60px);

    > div:last-child {
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      color: $font-3;
      margin-top: rem(16px);

      > span {
        color: red;
      }
    }
  }

  &__capitalisation-block {
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    color: $main-500;
    margin-top: rem(16px);

    span:nth-child(1) {
      color: $font-3;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      margin-right: rem(10px);
    }
  }

  &__companies-description {
    margin-top: rem(60px);
  }

  &__companies-description-blocks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: rem(96px);

    > span:first-child {
      font-weight: 700;
      font-size: rem(24px);
      color: $font-4;
      margin-bottom: rem(24px);

      @include mq($until: desktop) {
        text-align: center;
        margin: 0 auto;
        padding-bottom: rem(20px);
      }
    }

    img {
      @include mq($until: desktop) {
        text-align: center;
        margin: 0 auto;
      }
    }

    > span:last-child {
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      color: $font-3;
      margin-top: rem(24px);
    }
  }

  &__companies-description-blocks:nth-child(1) {
    margin-top: 0;
  }

  &__prev-years {
    margin: rem(72px 0 192px);

    > div {
      margin-top: rem(12px);
      display: flex;
      align-items: flex-end;
    }
  }
}
