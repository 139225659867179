.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: rem(2px);
  cursor: pointer;
  border: none;
  outline: none;
  font-family: $font;
  position: relative;
  font-weight: 600;
  transition: 0.2s ease;
  padding-left: rem(50px);
  padding-right: rem(50px);

  &:hover {
    text-decoration: none;
  }

  // types
  &.button-type-primary {
    background: $main-700;
    color: $white;

    &:hover {
      box-shadow: 0 20px 36px -10px rgba(65, 165, 240, 0.31);
    }

    &:hover,
    &:focus {
      background: $main-600;
    }

    &.loading {
      background: $main-700 !important;
    }

    &.active {
      background: $main-600;
    }

    &.disabled,
    &:disabled {
      background: $font-1;
      color: $font-2;
      cursor: not-allowed;
    }

    svg path {
      fill: $white;
    }

    .lds-ring {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      div {
        border-color: $white transparent transparent transparent;
      }
    }
  }

  &.button-type-secondary {
    background: $secondary-500;
    color: $white;

    &:hover {
      box-shadow: 0 20px 36px -10px rgba(65, 165, 240, 0.31);
    }

    &:hover,
    &:focus {
      background: $secondary-400;
    }

    &.loading {
      background: $secondary-500 !important;
    }

    &.active {
      background: $secondary-600;
    }

    &.disabled,
    &:disabled {
      background: $font-1;
      color: $font-2;
      cursor: not-allowed;
    }

    svg path {
      fill: $white;
    }

    .lds-ring {
      width: rem(25px);
      height: rem(25px);

      div {
        width: rem(25px);
        height: rem(25px);
        border-color: $white transparent transparent transparent;
      }
    }
  }

  &.button-type-invert {
    background-color: $grey-100;
    color: $main-700;

    &:hover {
      background: $main-700;
      color: $white;
      box-shadow: 0 20px 36px -10px rgba(7, 123, 231, 0.31);
    }

    &:active,
    &:focus {
      background: $main-700;
      color: $white;
    }

    &.disabled {
      background: $font-1;
      color: $font-2;
      cursor: not-allowed;
    }

    &.loading {
      background: $grey-100 !important;
      color: $main-700 !important;
    }

    svg path {
      fill: $main-500;
    }
  }

  &.button-type-transparent {
    border: 1px solid $main-700;
    background-color: $white;
    color: $main-700;

    &:hover {
      background: $main-700;
      color: $white;
      box-shadow: 0 20px 36px -10px rgba(7, 123, 231, 0.31);
    }

    &:active,
    &:focus {
      background: $main-600;
      color: $white;
    }

    &.loading {
      background: $white !important;
    }

    &.disabled {
      background: $white;
      border-color: $font-1;
      color: $font-2;
      cursor: not-allowed;
    }

    svg path {
      fill: $white;
    }
  }

  // sizes
  &.size-64 {
    height: rem(64px);
    letter-spacing: 0.02em;
    font-size: rem($text-body-font-1);
    line-height: rem(22px);

    .lds-ring,
    .lds-ring div {
      width: rem(25px);
      height: rem(25px);
      border-width: rem(2px);
    }
  }

  &.size-48 {
    font-weight: 500;
    font-style: normal;
    height: rem(48px);
    letter-spacing: 0.02em;
    font-size: rem($text-body-font-2);
    line-height: rem(18px);

    .lds-ring,
    .lds-ring div {
      width: rem(20px);
      height: rem(20px);
      border-width: rem(2px);
    }
  }

  &.size-32 {
    font-weight: 400;
    font-style: normal;
    font-size: rem(14px);
    height: rem(32px);
    line-height: 148%;
    letter-spacing: 0.04em;

    .lds-ring,
    .lds-ring div {
      width: rem(18px);
      height: rem(18px);
      border-width: rem(2px);
    }
  }
}
