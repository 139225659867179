.pagination {
  display: flex;
  justify-content: center;
  padding: rem(15px);

  @include mq($until: tablet) {
    padding: rem(15px) rem(5px);
  }

  .pages-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .icons {
      @include mq($until: small-tablet) {
        width: rem(10px);

        svg {
          display: block;
          margin-top: rem(0px);
          margin-left: rem(0px);
        }
      }
    }

    span:not(.link-disabled) {
      margin: 0 rem(16px);
      color: $font-3;
      cursor: default;

      @include mq($until: tablet) {
        margin: 0 rem(5px);
      }
    }

    > a:not(.button):focus {
      @include focus;
    }

    a,
    span.link-disabled {
      position: relative;
      color: $font-3;
      transition: 0.1s;

      &:hover {
        color: $font-5;

        svg path {
          stroke: $font-5;
        }
      }

      // prev button
      &:first-child {
        margin: 0 rem(16px) 0 0;
        padding-left: rem(25px);

        svg {
          left: 0;
        }
      }

      // next button
      &:last-child {
        margin: 0 0 0 rem(16px);
        padding-right: rem(25px);

        svg {
          right: 0;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        path {
          stroke: $font-3;
          fill: transparent;
        }
      }
    }

    span.link-disabled {
      color: $font-2 !important;
      cursor: not-allowed;

      svg path {
        stroke: $font-2 !important;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      border: 1px solid $font-3;
      box-sizing: border-box;
      border-radius: rem(2px);
      height: rem(32px);
      min-width: rem(32px);
      background-color: transparent;
      margin: 0 rem(8px);
      padding: 0 rem(5px);
      color: $font-3;
      cursor: pointer;
      font-size: rem($text-body-font-2);
      line-height: rem($text-body-height-2);

      @include mq($until: tablet) {
        margin: 0 rem(3px);
      }

      @include mq($until: small-tablet) {
        font-size: rem(12px);
        height: 100%;
        width: 100%;
        min-width: rem(10px);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &:focus {
        color: $font-3;
      }

      &.selected {
        border-color: $main-700;
        background: $main-700;
        color: $white;
      }

      &.loading {
        border-color: $main-200;
        background: $main-200;
        color: $white;
      }
    }

    a {
      @include link-styling;
    }
  }
}
