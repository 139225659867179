.insights-page {
  padding: rem(0) rem(30px);

  @include mq($until: desktop) {
    padding: rem(0) rem(15px);
  }

  .info-block h5 {
    max-width: rem(384px);
    width: 100%;
    margin-top: rem(20px);
    margin-bottom: rem(10px);
  }

  .features {
    span:nth-last-child(1) {
      font-weight: 500;
      color: $font-3;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }
  }

  .web-technologies__left {
    height: rem(20px);
    margin-top: rem(15px);

    > span {
      bottom: 10%;
      left: 25%;
    }
  }

  .web-technologies__right {
    line-height: rem($text-body-height-1);
  }

  .web-technologies__content {
    margin-bottom: rem(180px);
  }

  .web-tech-widget__wrapper-active {
    @include mq($until: desktop) {
      top: rem(50px);
    }
  }

  .item-grid-insights {
    grid-template-columns: repeat(3, 1fr);
    max-width: rem($layout-base-1);
    width: 100%;

    @include mq($until: small-desktop) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
