.our-team {
  .our-team__container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    max-width: rem($layout-base-1);
    width: 100%;
    margin: rem(64px) auto 0;

    div:nth-child(1),
    :nth-child(7) {
      .our-team__color:nth-child(1) {
        background-color: $main-500 !important;
      }
    }
    div:nth-child(2) {
      .our-team__color:nth-child(1) {
        background-color: #f62942 !important;
      }
    }
    div:nth-child(3),
    :nth-child(5) {
      .our-team__color:nth-child(1) {
        background-color: #ffb84f !important;
      }
    }
    div:nth-child(4),
    :nth-child(6) {
      .our-team__color:nth-child(1) {
        background-color: #07e796 !important;
      }
    }
  }

  .our-team__wrapper {
    width: calc(25% - 24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: rem(32px);
    margin-right: rem(32px);

    @include mq($until: desktop) {
      width: auto;
      margin: rem(20px);
    }
  }

  .our-team__wrapper:nth-child(4n) {
    margin-right: 0;

    @include mq($until: desktop) {
      margin-right: rem(20px);
    }
  }

  img {
    height: 100%;
  }

  h5 {
    margin: rem(8px 0 4px);
    color: $font-4;
  }

  span {
    color: $font-2;
  }

  .our-team__image {
    width: 100%;
    position: relative;
  }

  .our-team__color {
    width: rem(16px);
    height: rem(16px);
    border-bottom-left-radius: 50%;
    align-self: flex-end;
  }

  .our-team__rectangle {
    background: $white-secondary;
    transform: matrix(0, -1, -1, 0, 0, 0);
    width: rem(176px);
    height: rem(176px);
    border-top-right-radius: 50%;
    position: absolute;
    bottom: 7px;
    left: 0;
    z-index: -1;
  }
}
