.loader-border {
  border: 1px solid $grey-200;
  border-radius: 7px !important;
}

.infinity-report-loader {
  margin: 0 auto;
  max-width: rem(1268px);
  width: 100%;
  height: rem(230px);
  background: $white;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  border-radius: 0px 0px 7px 7px;
  border: rem(0px) rem(1px) rem(1px) rem(1px) solid $grey-200;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;

  &.infinity-report-loader-has-shadow {
    box-shadow: 0 7px 27px 3px rgba(31, 105, 179, 0.05);
  }

  .lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(65px);
    height: rem(65px);
  }
}

.loader-bottom {
  height: rem(150px);
  margin-top: rem(-136px);
  background: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 100%);
  box-shadow: rem(0) rem(7px) rem(27px) rem(-13px) rgba(31, 105, 179, 0.05);
  border-radius: 0;
}
