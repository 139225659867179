.author-page {
  &__author {
    margin: rem(152px auto 48px);
    max-width: rem($layout-base-2);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include mq($until: tablet) {
      padding-left: rem(20px);
      padding-right: rem(20px);
    }

    img {
      border-radius: rem(2px);
    }

    h1 {
      margin: rem(16px 0);
      color: $font-4;

      @include mq($until: tablet) {
        font-size: rem(35px);
      }
    }

    .author-text {
      max-width: rem(800px);
      font-weight: 400;
      line-height: rem(28.08px);
      color: $font-3;
    }

    span {
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      color: $font-3;
    }
  }
}
