.contact-us {
  margin-top: rem(152px);
  padding: rem(0 20px);

  @include mq($until: iphone-11) {
    margin-top: rem(48px);
  }

  h1 {
    margin-bottom: rem(130px);
    color: $font-4;
    text-align: center;

    @include mq($until: tablet) {
      margin: rem(60px 0 20px);
    }

    @include mq($until: iphone-11) {
      text-align: start;
      font-size: rem(40px);
      line-height: rem(44px);
    }
  }

  h2 {
    @include mq($until: tablet) {
      font-size: rem(34px) !important;
    }

    @include mq($until: iphone-11) {
      font-size: rem(32px);
      line-height: rem(36px);
    }
  }

  .plan-collapse {
    display: flex;
    justify-content: center;

    @include mq($until: desktop) {
      display: block;
    }

    &__left {
      position: relative;
      background-image: none;

      @include mq($until: desktop) {
        margin: 0 auto;

        h2 {
          text-align: center;
        }

        h5 {
          text-align: center;
        }
      }

      .plan-collapse-left__bg {
        position: absolute;
        margin: rem(-596px 0 0 -90px);

        svg {
          position: absolute;
          font-size: rem(485px);
          height: rem(622px);
          fill: none;
          pointer-events: none;
        }

        @include mq($until: desktop) {
          margin: rem(-576px 0 0 -20px);

          svg {
            font-size: rem(485px);
            height: rem(550px);
          }
        }

        @include mq($until: small-desktop) {
          display: none;
        }
      }
    }

    .contact-us__media {
      margin-top: rem(16px);

      @include mq($until: desktop) {
        justify-content: center;
        margin-bottom: rem(66px);
      }

      @include mq($until: iphone-11) {
        justify-content: flex-start;
        text-align: start;
      }
    }

    .contact-us__contact {
      > span {
        color: $font-3;
        font-size: rem(20px);
        line-height: rem($text-body-height-3);

        > a {
          color: $font-3;
          font-size: rem(20px);
        }
      }
    }

    .plan-collapse__right {
      form {
        @include mq($until: iphone-11) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .input-wrapper {
            max-width: 100%;
            margin: rem(20px 0);
          }
        }

        textarea {
          &::placeholder {
            font-family: $font;
            font-style: normal;
            font-weight: 400;
            font-size: rem(18px);
            line-height: 156%;
            color: $font-3;
          }

          @include mq($until: iphone-11) {
            width: 100%;
            align-self: flex-start;
          }
        }

        button {
          max-width: rem(384px);
        }
      }

      .sub-button-text {
        display: none;
      }
    }

    .input-wrapper {
      span {
        color: $font-3;

        @include mq($until: small-desktop) {
          left: rem(18px);
        }
      }

      input {
        @include mq($until: small-tablet) {
          max-width: rem(384px);
          width: 100%;
        }

        @include mq($until: iphone-11) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    @include mq($until: desktop) {
      .form-book form {
        max-width: rem(600px);
        width: 100%;
        margin: 0 auto;
        padding: rem(5px);
        text-align: center;
        border: none;
      }
    }
  }

  .contact-us__left {
    max-width: rem(481px);
    margin: auto 0;
    width: 100%;
    text-align: left;

    @include mq($until: desktop) {
      margin: auto;
    }

    @include mq($until: small-desktop) {
      padding: rem(0 50px 0);
    }

    @include mq($until: tablet) {
      font-size: rem(20px);
      margin-top: rem(20px);
    }

    @include mq($until: iphone-11) {
      text-align: start;
      padding: 0;
    }

    > h2 {
      margin: rem(0 0 56px);
      color: $font-4;

      @include mq($until: iphone-11) {
        text-align: start;
      }
    }

    > h5 {
      margin: rem(56px) 0 0;
      color: $font-4;

      @include mq($until: iphone-11) {
        text-align: start;
      }
    }
  }

  .contact-us__info {
    margin-top: rem(16px);
    max-width: rem(274px);
    color: $font-3;
  }

  .contact-us__contact {
    margin-top: rem(8px);
    display: flex;

    .icon-phone {
      margin: auto 0;
      font-size: rem(18px);
      height: rem(18px);
      fill: none;
    }

    .icon-email {
      margin: auto 0;
      font-size: rem(18px);
      height: rem(16px);
      fill: none;
    }

    > span {
      margin-left: rem(18px);

      @include mq($until: 321px) {
        margin-left: rem(5px);
      }
    }
  }

  .contact-us__social-media {
    margin-top: rem(40px);

    h5 {
      color: $font-4;
      margin: 0;

      @include mq($until: tablet) {
        font-size: rem(20px);
      }

      @include mq($until: iphone-11) {
        text-align: start;
      }
    }
  }

  .plan-line {
    max-width: rem(970px);
    margin: rem(64px auto 0);
    border: 1px solid $grey-200;
  }

  .plan-location-wrapper {
    margin: 0 auto;
    display: flex;
    max-width: rem(1008px);
    width: 100%;
    justify-content: center;

    @include mq($until: small-desktop) {
      flex-direction: column;
      align-items: center;
    }

    @include mq($until: small-desktop) {
      margin-bottom: rem(112px);
    }

    .plan-location {
      max-width: rem(488px);
      width: 100%;
      display: flex;
      margin: rem(64px 0 0);

      @include mq($until: desktop) {
        flex-direction: column;
      }

      @include mq($until: 499px) {
        margin: rem(64px 0 0);
      }

      @include mq($until: iphone-11) {
        margin: rem(56px 0 0);
      }
    }
  }
}
