.pricing {
  .platform {
    &__plans-page {
      margin-top: rem(64px);
      box-sizing: border-box;
      display: flex;
      justify-content: center;

      a {
        max-width: rem(198px);
        width: 100%;
      }

      .button {
        max-width: rem(198px);
        width: 100%;
      }

      @include mq($until: tablet) {
        flex-direction: column;
        align-items: center;

        > div {
          margin-bottom: rem(5px);
        }
      }

      > div {
        width: rem(198px);
        height: rem(48px);
        border: 1px solid $main-500;
        border-radius: rem(2px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      > div:nth-child(2) {
        background: $main-500;

        a {
          color: $white;
        }
      }

      a {
        width: 100%;
        height: 100%;
        color: $main-500;
        font-weight: 700;
        padding-top: rem(12px);
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }
    }
  }

  .plan-collapse-page {
    max-width: rem($layout-base-1);
    width: 100%;
    margin: rem(64px) auto 0;

    &__type-of-price {
      display: flex;
      justify-content: flex-start;
      margin-left: rem(360px);

      @include mq($until: desktop) {
        justify-content: center;
        margin: 0;
      }

      > span {
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }

      > span:nth-child(1) {
        color: $font-3;
        margin-right: rem(8px);
      }

      > span:nth-child(3) {
        color: $main-500;
        margin-left: rem(10px);
      }

      > span:nth-child(4) {
        font-size: rem(14px);
        color: $font-2;
        margin-left: rem(4px);
        padding-top: rem(4px);
      }

      input {
        -webkit-appearance: none;
        padding: rem(8px 16px);
        border: 1px solid $grey-200;
        border-radius: rem(36px);
        cursor: pointer;
        outline: none;
        background-color: $white;
        position: relative;
        transition: 0.3s all;
      }

      input::after {
        content: '';
        position: absolute;
        width: rem(12px);
        height: rem(12px);
        background-color: #07e796;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        top: rem(8px);
        left: rem(8px);
      }

      input:checked {
        background-color: #07e796;
      }

      input:checked::after {
        left: rem(24px);
        background-color: $white;
      }
    }

    &__api-plan-wrapper {
      display: flex;
      margin-top: rem(16px);
    }

    &__api-plan {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      position: relative;

      h2 {
        margin-top: rem(-50px);
        color: $font-4;

        @include mq($until: desktop) {
          margin-top: 0;
          font-size: rem(22px);
        }

        @include mq($until: small-tablet) {
          font-size: rem(20px);
        }
      }

      h5 {
        margin-top: rem(-25px);
        color: $font-2;
        font-style: normal;
        font-weight: 500;
        font-size: rem(24px);
        max-width: rem(280px);
        width: 100%;

        @include mq($until: desktop) {
          margin: 0;
          font-size: rem($text-body-font-2);
          line-height: rem($text-body-height-2);
        }
      }

      .icons {
        position: absolute;
        top: 0;

        @include mq($until: desktop) {
          display: none;
        }

        svg {
          position: absolute;
          fill: none;
        }

        svg:nth-child(1) {
          margin-left: rem(50px);
          margin-top: rem(-85px);
          font-size: rem(45px);
          height: rem(45px);
        }

        svg:nth-child(2) {
          margin-left: rem(245px);
          font-size: rem(22px);
          height: rem(22px);
        }

        svg:nth-child(3) {
          margin-top: rem(150px);
          margin-left: rem(20px);
          font-size: rem(14px);
          height: rem(14px);
        }

        svg:nth-child(4) {
          margin-top: rem(120px);
          margin-left: rem(210px);
          font-size: rem(31px);
          height: rem(31px);
        }
      }
    }

    &__table-wrapper {
      border-radius: rem(2px);

      .plan-collapse-page__table-set {
        .plan-collapse-page__table-blocks:nth-child(1) {
          text-align: left !important;

          > div {
            padding-left: rem(15px);
            padding-right: rem(15px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            border-right: none;

            @include mq($until: small-tablet) {
              padding-right: rem(5px);
              padding-left: rem(5px);
            }
          }

          > div + div {
            border-top: none;
          }
        }

        .plan-collapse-page__table-blocks:nth-child(2) {
          > div:nth-child(1) {
            border-right: none;
          }

          > div + div {
            border-right: none;
            border-top: none;
          }
        }

        .plan-collapse-page__table-blocks:nth-child(3) {
          > div:nth-child(1) {
            border-right: none;
          }

          > div + div {
            border-right: none;
            border-top: none;
          }
        }

        .plan-collapse-page__table-blocks:nth-child(4) {
          > div + div {
            border-top: none;
          }
        }
      }
    }
  }

  .plan-collapse-page__api-plan h5 {
    @include mq($until: small-tablet) {
      text-align: left;
    }
  }

  .plan-collapse-page__api-plan-blocks {
    h5 {
      margin: rem(4px) 0 rem(8px);
      font-weight: 700;
      font-size: rem(24px);
      line-height: rem(29.16px);
      max-width: rem(241px);
    }
    h5,
    h4 {
      @include mq($until: tablet) {
        font-size: rem(14px);
      }
    }

    > span {
      @include mq($until: tablet) {
        font-size: rem($text-body-font-2);
        line-height: rem($text-body-height-2);
      }
    }
  }

  .asked-questions {
    margin-top: rem(30px);

    @include mq($until: desktop) {
      padding: rem(0 20px);
    }
  }

  .asked-questions__blocks-item {
    max-width: rem(603px);
    width: 100%;
  }

  .question-block-item-hidden {
    display: none;
  }

  .display-flex-block-item {
    padding-left: rem(30px);
    padding-right: rem(10px);

    span {
      font-style: normal;
      color: $font-4;
      margin-right: rem(8px);
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }

    .question-block-item {
      display: flex;
      margin-bottom: rem(10px);

      > div {
        margin: 0;
        color: $font-3;
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }
    }
  }
}
