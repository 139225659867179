.pricing {
  .plan-collapse__title {
    margin-top: rem(10px);
  }

  .platform__data-page {
    @include mq($until: desktop) {
      margin-bottom: rem(46px);
    }
  }

  .plan-collapse__right {
    @include mq($until: desktop) {
      textarea {
        margin: 0 auto !important;
      }
    }
  }

  .input-wrapper {
    input {
      @include mq($until: small-tablet) {
        max-width: rem(324px);
        width: 100%;
      }
    }
  }

  .form-book form {
    @include mq($until: desktop) {
      max-width: rem(600px);
      width: 100%;
      margin: 0 auto;
      padding: rem(5px);
      text-align: center;
      border: none;
    }

    @include mq($until: small-tablet) {
      padding: rem(0px) rem(15px);
      span {
      }
    }

    h5 {
      margin: 0px !important;
    }
  }

  .platform {
    margin-top: rem(152px);
    text-align: center;

    @include mq($until: iphone-11) {
      margin-top: rem(48px);
    }

    h1 {
      max-width: rem(592px);
      width: 100%;
      margin: 0 auto;
      color: $font-4;

      @include mq($until: desktop) {
        padding: rem(0 20px);
      }

      @include mq($until: small-desktop) {
        font-size: rem(40px);
      }
    }

    &__plans {
      margin-top: rem(64px);
      box-sizing: border-box;
      display: flex;
      justify-content: center;

      @include mq($until: tablet) {
        flex-direction: column;
        align-items: center;

        > div {
          margin-bottom: rem(5px);
        }
      }

      > div {
        width: rem(198px);
        height: rem(48px);
        border: 1px solid $main-500;
        border-radius: rem(2px);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      > div:nth-child(1) {
        background: $main-500;

        a {
          color: $white;
        }
      }

      a {
        width: 100%;
        height: 100%;
        color: $main-500;
        font-weight: 700;
        padding-top: rem(12px);
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }
    }

    &__customers {
      margin: rem(166px 0 124px);

      > div {
        background: none;
      }
    }
  }

  .asked-questions__blocks-item {
    width: 100%;
    max-width: rem(603px);
  }

  .question-block-item-hidden {
    display: none;
  }

  .display-flex-block-item {
    padding-left: rem(30px);
    padding-right: rem(10px);

    span {
      font-style: normal;
      color: $font-4;
      margin-right: rem(8px);
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }

    .question-block-item {
      display: flex;
      margin-bottom: rem(10px);

      > div {
        margin: 0;
        color: $font-3;
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
      }
    }
  }

  .asked-questions__blocks {
    > div {
      @include mq($until: tablet) {
        padding-right: rem(50px);
      }

      @include mq($until: small-tablet) {
        height: 100%;
      }
    }
  }

  .customer-block-container {
    background: linear-gradient(360deg, $grey-000 0%, rgba(245, 249, 252, 0) 100%);
  }
}
