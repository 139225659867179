.customer-block-clients {
  display: flex;
  justify-content: space-between;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  gap: rem(35px);

  &.has-background {
    background: linear-gradient(360deg, $grey-000 0%, rgba(245, 249, 252, 0) 100%);
  }

  @include mq($until: wide) {
    flex-flow: row wrap;
    justify-content: space-around;
  }
}
