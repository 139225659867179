@include fade-exact-background('fadeMask', rgba(0, 0, 0, 0.4));
@include fade-exact-background('fadeSemitransparentMask', rgba(0, 0, 0, 0.01));

.gdb-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9997;

  &.gdb-mask {
    &-black {
      animation: fadeMask 500ms forwards;
    }

    &-semitransparent {
      animation: fadeSemitransparentMask 250ms forwards;
    }
  }
}
