.roadmap {
  max-width: rem($layout-base-2);
  width: 100%;
  margin: rem(192px) auto;

  h2,
  h5 {
    color: $font-4;
    margin: 0;
  }

  h5 {
    @include mq($until: desktop) {
      margin-top: rem(10px);
      margin-bottom: rem(15px);
    }
  }

  &__info {
    > span {
      font-size: rem(14px);
      color: $main-500;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: desktop) {
      flex-direction: column;
      text-align: center;
    }

    > span {
      max-width: rem(488px);
      width: 100%;
      font-size: rem(20px);
      color: $font-2;
    }
  }

  &__wrapper:nth-child(2) {
    margin-top: rem(96px);

    @include mq($until: desktop) {
      margin-top: rem(20px);
    }
  }

  &__wrapper {
    padding-top: rem(124px);
    display: flex;
    position: relative;

    @include mq($until: desktop) {
      padding-top: rem(60px);
      flex-direction: column;
      align-items: center;
      position: static;
    }
  }

  &__wrapper {
    &::before {
      position: absolute;
      content: '';
      top: 80%;
      width: rem(2px);
      height: rem(165px);

      &:last-child {
        content: none;
      }

      @include mq($until: desktop) {
        content: none;
      }
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &.intersected {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D8E5F7FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  &__image {
    position: relative;
    margin-right: rem(71px);

    @include mq($until: desktop) {
      margin-right: rem(0);
    }

    svg {
      position: absolute;
      top: 40%;
      right: 50%;
      font-size: rem(24px) !important;
      height: rem(24px);
      fill: none;
    }

    > span {
      font-size: rem(24px);
      color: $main-500;
      font-weight: 700;
      margin-bottom: rem(4px);
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mq($until: desktop) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: rem(40px);
    }
  }

  &__descr {
    max-width: rem(488px);
    width: 100%;
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    color: $font-3;
  }
}
