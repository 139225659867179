.burger-content {
  animation: fade-right 0.1s linear;
  max-width: rem(400px);
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 110%;
  @include hidden-scroll;
  padding-top: rem(100px);

  &.intersected {
    background-image: url('/storage/svg/decoration/decoration.svg');
  }

  @include mq($until: small-tablet) {
    overflow: auto;
  }

  a {
    @include link-styling;
  }

  .burger-menu {
    width: 100%;
    font-size: rem(20px);

    @include mq($until: small-tablet) {
      overflow: auto;
      margin-bottom: 180px;
    }
  }

  .close-burger-menu {
    position: absolute;
    top: rem(70px);
    right: rem(21px);
    cursor: pointer;

    svg {
      font-size: rem(22px);

      path {
        fill: $main-500;
      }
    }
  }

  .gdb-icon-hamburger {
    @include mq($until: small-tablet) {
      display: none;
    }
  }

  .burger-active-buttons {
    position: relative;
    display: grid;
    grid-template-columns: 49% 48%;
    grid-column-gap: rem(10px);
    margin: rem(40px 20px 20px);

    a {
      width: 100%;
      text-wrap: nowrap;
      padding: initial;
    }
  }
}

.close-btn-label {
  position: absolute;
  top: rem(15px);
  right: rem(25px);
  z-index: 10000;
  cursor: pointer;

  svg:nth-child(1) {
    width: rem(20px);
    height: rem(30px);

    path {
      fill: $font-3;
    }
  }
}
