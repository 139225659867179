.plan-collapse-page {
  svg {
    font-size: rem(16px);
    fill: none;
  }

  &__table-blocks {
    background: $white-secondary;
  }
}
