.touch-credit-search-wrapper {
  display: flex;
  justify-content: space-between;
  padding: rem(16px 0);
  margin-bottom: rem(16px);
  border-radius: 1px;

  @include mq($until: tablet) {
    margin-bottom: rem(16px);
  }

  form {
    display: flex;
    margin: 0 auto;
    transition: 0.2s ease;
    max-width: rem(600px);
    width: 100%;

    @include mq($until: tablet) {
      flex-direction: column;
    }

    @include mq($until: iphone-11) {
      max-width: 100%;
      width: 100%;
    }

    .input-wrapper {
      margin: 0;

      @include mq($until: tablet) {
        margin: rem(12px 0);
      }

      @include mq($until: iphone-11) {
        max-width: 100%;
        width: 100%;
      }

      input {
        border-radius: 2px 0 0 2px;

        @include mq($until: tablet) {
          height: rem(56px);
          border-radius: 2px;

          &:focus {
            + span {
              top: rem(3px);
            }
          }
        }

        @include mq($until: iphone-11) {
          max-width: 100%;
          width: 100%;
        }
      }

      .input-label {
        @include mq($until: tablet) {
          top: rem(15.3px);
        }
      }
    }

    button {
      border-radius: 0 2px 2px 0;
      min-width: rem(208px);

      @include mq($until: tablet) {
        min-width: rem(182px);
        height: rem(56px);
        border-radius: 2px;
        align-self: center;
      }

      @include mq($until: iphone-11) {
        width: 100%;
      }
    }
  }
}
