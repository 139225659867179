.color-table {
  margin: 0 auto;
  height: rem(64px);
  width: rem(384px);
  text-align: center;
  padding: rem(21px) rem(0px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  span {
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $white;
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
  }

  &-blue {
    margin: 0 auto;
    height: rem(64px);
    width: rem(384px);
    text-align: center;
    padding: rem(21px) rem(0px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: $main-500;

    @include mq($until: small-tablet) {
      width: rem(290px);
    }

    @include mq($until: iphone-11) {
      width: 100%;
    }

    span {
      font-weight: 700;
      letter-spacing: 0.02em;
      color: $white;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      @include mq($until: small-tablet) {
        font-size: rem(14px);
      }
    }
  }

  &-orange {
    margin: 0 auto;
    height: rem(64px);
    width: rem(384px);
    text-align: center;
    padding: rem(21px) rem(0px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #ffb84f;

    @include mq($until: small-tablet) {
      width: rem(290px);
    }

    @include mq($until: iphone-11) {
      width: 100%;
    }

    span {
      font-weight: 700;
      letter-spacing: 0.02em;
      color: $white;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      @include mq($until: small-tablet) {
        font-size: rem(14px);
      }
    }
  }

  &-green {
    margin: 0 auto;
    height: rem(64px);
    width: rem(384px);
    text-align: center;
    padding: rem(21px) rem(0px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #07e796;

    @include mq($until: small-tablet) {
      width: rem(290px);
    }

    @include mq($until: iphone-11) {
      width: 100%;
    }

    span {
      font-weight: 700;
      letter-spacing: 0.02em;
      color: $white;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      @include mq($until: small-tablet) {
        font-size: rem(14px);
      }
    }
  }
}
