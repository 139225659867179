.request-demo {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0d267f;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  &.request-demo-size {
    // .request-demo-size-compact
    &-compact {
      .request-demo__wrapper {
        margin: rem(50px 0) !important;
        padding: rem(46px 0);

        h2 {
          font-size: rem(32px);
        }
      }
    }
  }

  @include mq($until: wide) {
    padding: rem(0px) rem(45px);
    margin: rem(100px) auto 0;
  }

  @include mq($until: tablet) {
    background-image: inherit !important;
  }

  @include mq($until: small-tablet) {
    padding: rem(0px) rem(20px);
    margin-top: rem(30px);
  }

  @include mq($until: iphone-11) {
    margin-bottom: rem(112px);
  }

  &.gray {
    background-color: $white-secondary;

    @include mq($until: small-tablet) {
      margin-top: rem(100px);
    }

    h2 {
      color: $font-4;

      @include mq($until: small-desktop) {
        font-size: rem(40px);
        line-height: rem(43.2px);
      }
    }
  }

  &.alternative {
    margin: rem(130px auto 0);
  }

  .request-demo__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: rem(112px 0);

    @include mq($until: small-desktop) {
      display: block;
      padding: rem(50px 0);
    }

    @include mq($until: small-tablet) {
      padding-top: rem(60px);
    }
  }

  .request-demo__button {
    display: flex;
    align-items: center;
    height: 100%;

    @include mq($until: small-desktop) {
      padding-top: rem(30px);
      justify-content: center;
    }

    @include mq($until: iphone-11) {
      a {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  a:focus {
    outline: none;

    button {
      background: $main-400;
    }
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    width: rem(240px);
    height: rem(64px);
    background: $main-500;
    border-radius: rem(2px);
    letter-spacing: 0.02em;
    color: $white;
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
  }

  &__button {
    @include mq($until: desktop) {
      text-align: center;
      margin: 0 auto;
    }
  }

  &__title {
    width: 60%;

    @include mq($until: small-desktop) {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      padding: 0 rem(15px);
      padding-top: rem(20px);
    }

    @include mq($until: iphone-11) {
      padding: 0;
    }
  }

  h2 {
    margin: 0;
    color: $white;
    font-size: rem(40px);
    line-height: rem(48px);

    @include mq($until: desktop) {
      font-size: rem(30px);
    }

    @include mq($until: tablet) {
      font-size: rem(28px);
      line-height: rem(32px);
    }
  }
}

.request-demo-loader {
  height: rem(320px);
  background-color: #0d267f;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;

  &.gray {
    background-color: $white-secondary;
  }
}
