.features {
  max-width: rem($layout-base-2);
  width: 100%;
  margin: rem(72px) auto 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: rem(32px);

  @include mq($until: wide) {
    grid-template-columns: repeat(3, 2fr);
    grid-gap: rem(15px);
    padding: rem(0px) rem(15px);
  }

  @include mq($until: desktop) {
    grid-template-columns: repeat(2, 3fr);
  }

  @include mq($until: small-tablet) {
    grid-template-columns: repeat(1, 5fr);
  }

  > div {
    border: 1px solid $grey-100;
    border-radius: rem(2px);
    padding: rem(32px 24px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  span {
    margin-top: rem(16px);
    font-size: rem(20px);
    color: $font-4;
    flex-grow: 3;
    font-weight: 700;
  }

  > div:nth-child(5) {
    span {
      width: 30px;
    }
  }

  svg {
    margin-bottom: rem(8px);
    font-size: rem(40px) !important;
    height: rem(40px);
    fill: none;
  }
}
