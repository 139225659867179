.gdb-checkbox {
  display: flex;
  cursor: pointer;

  &:hover {
    .gdb-checkbox-label {
      color: $font-5;
    }

    .gdb-checkbox-input-wrapper {
      border-color: $main-400;
    }
  }

  &.checked {
    .gdb-checkbox-label {
      color: $font-5;
    }

    .gdb-checkbox-input-wrapper {
      background-color: $main-500;
      border-color: $main-500;

      .gdb-checkbox-icon path {
        fill: $white;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    .gdb-checkbox-label {
      color: $font-2;
    }

    .gdb-checkbox-input-wrapper {
      background-color: $grey-500;
      border-color: $grey-500;
    }
  }

  .gdb-checkbox-input-wrapper {
    position: relative;
    width: rem(20px);
    height: rem(20px);
    border-radius: rem(2px);
    border: 1px solid $grey-200;
    margin: rem(4px 0px);
    transition: all 0.1s;

    .gdb-checkbox-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: rem(10px);

      path {
        fill: $white;
      }
    }

    .gdb-checkbox-input {
      display: none;
    }
  }

  .gdb-checkbox-label {
    color: $font-3;
    margin-left: rem(8px);
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    transition: all 0.1s;
  }
}
