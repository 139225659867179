.find-more {
  background-color: $white-secondary;
  padding: rem(60px 80px 38px);
  margin: rem(36px 50px 36px 0);

  @include mq($until: desktop) {
    flex-direction: column;
    padding: rem(60px 20px);
  }

  @include mq($until: tablet) {
    margin-right: 0;
  }

  &__description-block {
    padding-right: rem(30px);
    padding-bottom: rem(15px);

    @include mq($until: desktop) {
      text-align: center;
      padding-right: rem(0);
    }

    h3 {
      font-size: rem(30px);
      margin-top: 0;
      color: $font-4;
      line-height: rem(34px);
    }

    p {
      max-width: rem(600px);
      font-size: rem(22px);
      line-height: rem(28px);
      color: $font-3;

      @include mq($until: desktop) {
        margin: rem(0 auto 30px);
      }
    }
  }

  a {
    margin: rem(auto 0);

    @include mq($until: desktop) {
      margin: rem(0 auto);
    }
  }
}

.find-more-loader {
  height: rem(300px);
  width: 100%;
  max-width: rem($layout-base-2);
  background-color: $white-secondary;
}
