.select {
  position: relative;

  &.has-error {
    margin-bottom: rem(25px);

    .rc-select {
      border-color: $danger;
    }
  }

  @include mq($until: tablet) {
    width: 100%;
  }

  .rc-select {
    width: 100%;
    font-weight: 400;
    background: $white;
    border: 1px solid $grey-200;
    box-sizing: border-box;
    border-radius: rem(2px);
    line-height: rem($text-body-height-1);

    &.rc-select-focused {
      border: 1px solid $main-600 !important;
      box-sizing: border-box;
    }

    & .rc-select-selection-search-input {
      color: $font-4;
      font-weight: 400;
      height: 100%;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);

      &[aria-hidden='true'] {
        display: none;
      }
    }

    & .rc-select-selection-placeholder {
      opacity: 1;
      color: $grey-700 !important;
    }

    & .rc-select-selection-placeholder,
    & .rc-select-selection-item {
      top: 0 !important;
      color: $font-3;
      font-weight: 400;
      padding: rem(0 16px);
      font-size: rem(18px);
      line-height: rem(14px);
      display: flex;
      text-align: center;
      align-items: center;
      height: 100%;

      span {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }

    & input {
      padding: rem(16px) rem(18px) rem(18px) rem(18px);
      background: transparent !important;
    }

    .rc-select-selector {
      height: 100%;
      border: none !important;
    }
  }

  & .rc-select-show-arrow .rc-select-arrow {
    top: rem(25px);
    right: rem(20px);
    transform: translate3d(0, -50%, 0);
  }

  & .rc-select-show-arrow .rc-select-arrow-icon::after {
    content: '';
    border: none;
    width: rem(12px);
    height: rem(6px);
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23425289' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.select-size-64 {
    .rc-select {
      height: rem(64px);
      font-size: rem($text-body-font-1);
    }
  }

  &.select-size-48 {
    .rc-select {
      height: rem(48px);
      font-size: rem(14px);

      input {
        padding: rem(0 10px);
        font-size: rem($text-body-font-2);
      }

      .rc-select-selection-placeholder,
      .rc-select-selection-item {
        top: 0;
        display: flex;
        align-items: center;
        padding: rem(8px);
        font-size: rem($text-body-font-2);
      }

      .rc-select-arrow {
        top: rem(16px);
        right: rem(10px);
      }
    }
  }

  &.select-size-32 {
    .rc-select {
      height: rem(32px);
      font-size: rem(14px);

      input {
        padding: rem(0 10px);
        font-size: rem(14px);
      }

      .rc-select-selection-placeholder,
      .rc-select-selection-item {
        top: 0;
        padding: rem(1px 8px);
        font-size: rem(14px);
      }

      .rc-select-arrow {
        top: rem(10px);
        right: rem(10px);
      }
    }
  }
}

.rc-select-item-option-state-icon {
  position: absolute;
  right: rem(20px);
  color: $font-3;
}

.rc-select-dropdown {
  min-height: rem(180px) !important;
  border: 1px solid $main-600 !important;
  box-shadow: 0px 48px 48px rgba(12, 37, 127, 0.08);
  border-radius: 2px;
  z-index: 999;

  .rc-select-item-option-content {
    font-weight: 400;
    font-family: $font;
    padding-left: rem(4px);
    cursor: pointer;
    font-size: rem($text-body-font-2);
    line-height: rem($text-body-height-1);
  }

  & .rc-select-item-option-active {
    width: 100%;
    border-color: rem(1px) $main-500 solid !important;
    background: $main-500;
    color: $white !important;
  }

  & .rc-select-item-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .rc-virtual-list-holder {
    height: rem(250px);
    position: relative;
    overflow: hidden;
  }

  .rc-virtual-list-holder-inner {
    max-width: 100%;
    width: 100%;
    position: absolute;
    border-color: rem(1px) $main-500 solid !important;
  }
}
