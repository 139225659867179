.benefits-blog {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  display: flex;

  @include mq($until: wide) {
    padding: rem(0 18px);
    display: block;
  }

  @include mq($until: small-desktop) {
    display: block;
    padding: 0;
  }

  @include mq($until: iphone-11) {
    display: block;
    padding: 0;
  }
}

.benefits-wrapper {
  background: $white-secondary;
  padding: rem(92px 0);
  margin: rem(192px 0 -222px);

  @include mq($until: wide) {
    margin: 0;
  }

  @include mq($until: iphone-11) {
    padding: rem(56px 0 74px);
  }

  .benefits {
    max-width: rem($layout-base-1);
    width: 100%;
    margin: 0 auto;

    h2 {
      text-align: center;
      margin: rem(0 0 64px);
      color: $font-4;

      @include mq($until: iphone-11) {
        text-align: start;
      }
    }
  }
}

.benefits-view-more {
  text-align: center;
  margin: 0 auto;
  display: flex;
  width: fit-content;

  @include mq($until: iphone-11) {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
