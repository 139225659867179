.platform {
  .plan-collapse__title {
    @include mq($until: wide) {
      text-align: left;
    }
  }

  .form-book form {
    margin-top: rem(75px);

    .input-wrapper {
      span {
        @include mq($until: small-desktop) {
          left: rem(18px);
        }
        @include mq($until: 499px) {
          left: rem(18px);
        }
        @include mq($until: 321px) {
          left: rem(18px);
        }
      }
    }

    @include mq($until: 499px) {
      button {
        max-width: rem(324px) !important;
      }

      .sub-button-text {
        max-width: rem(324px) !important;
      }
    }
  }

  &__data-page {
    margin-top: rem(64px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    a {
      max-width: rem(198px);
      width: 100%;
    }

    .button {
      max-width: rem(198px);
      width: 100%;
    }

    @include mq($until: tablet) {
      flex-direction: column;
      align-items: center;

      > div {
        margin-bottom: rem(5px);
      }
    }

    > div {
      width: rem(198px);
      height: rem(48px);
      border: 1px solid $main-500;
      border-radius: rem(2px);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    > div:nth-child(3) {
      background: $main-500;

      a {
        color: $white;
      }
    }

    a {
      width: 100%;
      height: 100%;
      color: $main-500;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      font-weight: 700;
      padding-top: rem(12px);
    }
  }
}

.plan-collapse__bg {
  @include mq($until: desktop) {
    display: none;
  }
}

.asked-questions__blocks-item {
  width: 100%;
  max-width: rem(603px);
}

.question-block-item-hidden {
  display: none;
}

.display-flex-block-item {
  padding-left: rem(30px);
  padding-right: rem(10px);

  span {
    font-style: normal;
    color: $font-4;
    margin-right: rem(8px);
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
  }

  .question-block-item {
    display: flex;
    margin-bottom: rem(10px);

    > div {
      margin: 0;
      color: $font-3;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }
  }
}
