.custom-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: rem(150px);

  @include mq($until: tablet) {
    margin-bottom: rem(192px);
  }

  h2 {
    display: inline-block;
    font-size: rem(44px);
    font-weight: 400;
    color: $font-4;
    margin: 0 0 25px;
    border-bottom: 2px solid $main-500;
    padding: 0 0 15px;
  }

  span {
    margin-top: rem(47.93px);
    font-size: rem(24px);
    line-height: rem(32.64px);
    font-weight: 500;
    color: $font-3;

    @include mq($until: small-desktop) {
      margin-top: rem(40px);
    }

    @include mq($until: tablet) {
      margin-top: rem(20px);
    }

    @include mq($until: small-tablet) {
      margin-top: 0;
    }

    @include mq($until: iphone-x) {
      margin-top: rem(-20px);
    }
  }

  a {
    margin-top: rem(16px);
  }

  svg {
    vertical-align: middle;
    font-size: rem(800px) !important;
    height: rem(309px);
    fill: none;

    @include mq($until: small-desktop) {
      font-size: rem(700px) !important;
    }

    @include mq($until: tablet) {
      font-size: rem(520px) !important;
    }
    @include mq($until: small-tablet) {
      font-size: rem(420px) !important;
    }
    @include mq($until: iphone-x) {
      font-size: rem(360px) !important;
    }
  }

  .error-bg-rectangles {
    position: absolute;
    width: rem(800px);
    height: rem(360px);
    background-repeat: no-repeat;
    margin-left: rem(80px);
    margin-top: rem(-27.2px);

    &.intersected {
      background-image: url('/storage/svg/base/404-bg-rectangles.svg');
    }

    @include mq($until: small-desktop) {
      width: rem(670px);
      margin-top: rem(-8px);
      margin-left: rem(21.6px);
    }

    @include mq($until: tablet) {
      width: rem(500px);
      margin-top: rem(33.6px);
      margin-left: rem(11.2px);
      height: rem(243.2px);
    }

    @include mq($until: small-tablet) {
      width: rem(400px);
      margin-top: rem(57.6px);
      margin-left: rem(11.2px);
      height: rem(213.2px);
    }

    @include mq($until: iphone-x) {
      width: rem(350px);
      margin-top: rem(68.8px);
      margin-left: rem(4px);
      height: rem(181.2px);
    }
  }
}
