.country-promotion {
  &:last-child {
    margin-bottom: rem(100px);
  }

  .info-wrapper {
    max-width: rem(1216px);
    display: flex;
    justify-content: space-between;
    margin: rem(100px auto);
    padding: rem(0 16px);

    @include mq($until: desktop) {
      flex-direction: column;
    }

    .info-block-promotion {
      align-self: center;

      @include mq($until: desktop) {
        margin-bottom: rem(60px);
      }
    }

    .form-book {
      @include mq($until: desktop) {
        align-self: center;
      }

      h5 {
        margin: 0;
        text-align: center;
      }
    }
  }

  .clients {
    background-color: $grey-000;
    padding: rem(80px 0);

    .info-block {
      margin: 0;

      .info-block__wrapper {
        background-color: $grey-000;
        margin-bottom: rem(42px);

        h1 {
          font-size: rem(40px);
          line-height: rem(48px);
          margin: rem(0 0 4px);
        }

        h5 {
          max-width: rem(718px);
          color: $font-3;
          font-size: rem(18px);
          line-height: rem(24px);
        }
      }
    }
  }

  .gdb-solutions {
    max-width: rem(1216px);
    margin: rem(100px auto);

    .info-block {
      margin: rem(0 0 40px);

      .info-block__wrapper {
        h1 {
          margin: 0;
          font-size: rem(40px);
          line-height: rem(48px);
        }
      }
    }

    .solution-description {
      @include mq($until: wide) {
        padding: rem(0 15px);
      }

      @include mq($until: desktop) {
        flex-direction: column;
      }

      .solution-description-stat {
        @include mq($until: desktop) {
          align-self: center;

          &:not(:last-child) {
            margin-bottom: rem(32px);
            margin-right: 0;
          }
        }
      }

      .solution-description-count {
        white-space: nowrap;
      }
    }
  }

  .industry-promotion {
    margin-bottom: rem(100px);
  }

  .content-grey-bg {
    background-color: $grey-000;
    padding: rem(120px 0);

    .has-country-promotion {
      background-image: none !important;
      background-color: $grey-000;
      border: 1px solid $secondary-500;

      h5 {
        color: $font-4 !important;
        max-width: rem(568px);

        @include mq($until: small-desktop) {
          margin: auto;
        }
      }

      .request-demo__wrapper {
        padding: rem(44px 0);
      }
    }

    .email-promotion {
      margin-bottom: rem(80px);

      .profile-description-stat {
        padding: rem(24px) !important;

        &:nth-child(3) {
          border: 1px solid $secondary-500;

          .profile-description-stat-count {
            white-space: nowrap;
          }
        }
      }
    }

    .people-feedback {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: rem(80px 0 0);

      &__image {
        width: rem(64px);
        height: rem(64px);
        border-radius: 50%;
        overflow: hidden;
      }

      > div:nth-child(1) {
        h2 {
          font-size: rem(48px);
          line-height: rem(56px);
          color: $font-4;
          max-width: rem(672px);
          width: 100%;
        }
      }

      &__container {
        display: flex;
        justify-content: center;
        max-width: rem($layout-base-1);
        width: 100%;
        margin-top: rem(32px);

        @include mq($until: wide) {
          padding-left: rem(10px);
          padding-right: rem(10px);
        }

        @include mq($until: desktop) {
          display: block;
        }
      }

      &__wrapper {
        max-width: rem(384px);
        width: 100%;
        height: rem(484px);
        background: $white;
        border-radius: rem(2px);
        padding: rem(64px) rem(40px);

        @include mq($until: desktop) {
          margin: rem(15px) auto;
          padding: rem(40px) rem(35px);
          width: rem(420px);
        }

        @include mq($until: small-tablet) {
          width: rem(280px);
        }
      }

      &__wrapper:nth-child(2) {
        margin: 0 rem(32px);

        @include mq($until: desktop) {
          margin: 0 auto;
        }
      }

      &__quotes {
        display: flex;
        align-items: center;

        .icons:nth-child(1) {
          margin-left: rem(20px);
          z-index: 3;
        }

        svg {
          margin-left: rem(2px);
        }

        > svg:nth-child(1) {
          font-size: rem(40px);
          height: rem(32px);
          fill: none;
          z-index: 1;
        }

        > svg:nth-child(2) {
          margin-left: rem(-20px);
          font-size: rem(80px);
          height: rem(80px);
          fill: none;
        }
      }

      h2 {
        margin: 0;
        max-width: rem(592px);
        width: 100%;
        text-align: center;

        @include mq($until: desktop) {
          text-align: center;
        }

        @include mq($until: tablet) {
          padding: rem(0px) rem(15px);
        }
      }

      &__text {
        margin-top: rem(40px);
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
        color: $font-3;
      }

      &__people {
        display: flex;
        margin-top: rem(32px);
      }

      h5 {
        margin: 0;
        color: $font-4;
      }

      &__info {
        display: flex;
        align-items: center;
        color: $font-2;
        margin-left: rem(8px);
      }

      @include mq($until: tablet) {
        margin-bottom: rem(-48px);
      }
    }
  }

  .video-container-wrapper {
    background: none;
    padding: 0;
    margin: rem(100px auto);

    .video-container {
      display: flex;
      flex-direction: column;

      .video-container__info {
        flex-direction: row;
        justify-content: space-between;
        max-width: 100%;
        margin: rem(0 0 67px);

        @include mq($until: desktop) {
          flex-direction: column;
        }

        h3 {
          max-width: rem(592px);
          font-size: rem(48px);
          line-height: rem(56px);
        }

        span {
          font-size: rem(20px);
          line-height: rem(28px);
        }
      }

      &__video {
        align-self: center;
        display: flex;
        justify-content: center;
        max-width: 100%;

        @include mq($until: desktop) {
          max-width: rem(1008px);
        }

        .presentation-type-text-icon {
          margin: rem(0 15px);
          max-width: rem(1144px);
          height: rem(664px);

          @include mq($until: small-desktop) {
            height: rem(560px);
          }

          @include mq($until: tablet) {
            height: rem(420px);
          }

          @include mq($until: small-tablet) {
            height: rem(380px);
          }
        }
      }
    }
  }
}
