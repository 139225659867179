.plan-location {
  .headquarter,
  .operational-office {
    display: flex;
    flex-direction: column;
    max-width: rem(488px);
  }
  .icon {
    position: absolute;
    margin: rem(-9.5px 0 0 16.5px);
  }

  .gdb-icon-headquarter-building {
    font-size: rem(30px);
    height: rem(28px);
    fill: none;
  }
  .gdb-icon-operational-office-building {
    font-size: rem(22px);
    height: rem(28px);
    fill: none;
  }

  .rectangle-icon {
    align-self: flex-start;
    width: rem(36px);
    height: rem(36px);
    background-color: $grey-100;
    border-top-right-radius: 50%;
  }

  &__block {
    margin-top: rem(8px);

    span {
      font-size: rem(20px);
      line-height: rem(27.2px);
      font-weight: 500;
      color: $font-4;
    }

    p {
      margin: rem(4px 0 0 0);
      font-size: rem(20px);
      line-height: rem(27.2px);
      font-weight: 500;
      color: $font-3;
    }

    @include mq($until: iphone-11) {
      span {
        font-size: rem(24px);
        font-weight: rem(28px);
      }

      p {
        font-size: rem(16px);
        font-weight: rem(20px);
      }
    }
  }
}
