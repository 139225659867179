.asked-questions {
  display: flex;
  margin: rem(192px auto);
  max-width: $layout-base-1;

  @include mq($until: desktop) {
    flex-direction: column;
  }

  @include mq($until: wide) {
    padding-left: rem(24px);
    padding-right: rem(24px);
  }

  @include mq($until: tablet) {
    margin-bottom: rem(50px);
  }

  @include mq($until: small-tablet) {
    margin: rem(96px 0);
  }

  @include mq($until: iphone-11) {
    margin: rem(56px 0 96px);
  }

  h2 {
    text-align: left;
    margin: 0;
    margin-right: rem(40px);
    color: $font-4;
    cursor: pointer;

    @include mq($until: desktop) {
      text-align: center;
      max-width: rem(550px);
      margin: rem(0 auto 50px);
    }

    @include mq($until: small-tablet) {
      margin-bottom: rem(10px);
      font-size: rem(30px);
    }

    @include mq($until: iphone-11) {
      text-align: start;
    }
  }

  .asked-questions__wrapper {
    width: 100%;
  }

  .asked-questions__blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > div {
      background-color: $white;
      width: rem(800px);
      min-height: rem(64px);
      align-items: center;
      border: 1px solid $grey-200;
      border-radius: rem(2px);
      margin-top: rem(8px);
      font-weight: 500;
      font-size: rem(20px);
      color: $font-4;
      position: relative;
      line-height: rem($text-body-height-3);

      &.question-disabled {
        h2 {
          cursor: not-allowed;
          color: $font-2;
        }

        .gdb-icon path {
          stroke: $font-2 !important;
        }
      }

      @include mq($until: desktop) {
        max-width: 100%;
        width: 100%;
      }

      @include mq($until: tablet) {
        padding-right: rem(40px);
      }

      @include mq($until: small-tablet) {
        margin-top: rem(20px);
        height: 100%;
      }

      h2,
      h3 {
        font-weight: 500;
        color: $font-4;
        line-height: rem(27px);
        margin: 0;
        padding: rem(18px) rem(20px);
        padding-right: rem(48px);
        font-size: rem(20px);
        cursor: pointer;
      }

      h3 {
        padding: 0 rem(20px) rem(12px);
      }

      p {
        margin: 0;
        padding: rem(18px) rem(20px);
        font-size: rem(18px);
        font-weight: 400;
        color: $font-3;
      }

      ol,
      ul {
        margin: 0;
        padding: rem(0 35px 18px);

        ol,
        ul {
          padding: rem(0 22px 18px);
        }

        li {
          padding-left: 0;
          font-size: rem(17px);
          color: $font-3;

          &:not(:last-child) {
            margin-bottom: rem(5px);
          }

          &::marker {
            color: $font-4;
          }

          p {
            padding: 0;
          }
        }
      }

      ul {
        list-style: disc;
      }

      .gdb-icon {
        position: absolute;
        top: rem(17px);
        right: rem(20px);
        pointer-events: none;
        fill: none;
        font-size: rem(24px);
        transition: transform 0.1s;

        &.unactive {
          transform: rotate(45deg);
        }
      }

      a {
        margin-left: rem(2.5px);
        margin-right: rem(2.5px);
      }
    }

    > div:first-child {
      margin-top: 0;
    }
  }

  svg {
    font-size: rem($text-body-font-2);
    line-height: rem($text-body-height-2);

    path {
      stroke: $main-700;
    }
  }
}

.asked-questions__blocks-item {
  width: 100%;
  max-width: rem(603px);
}
