.plan-collapse {
  &.plan-collapse-flex {
    display: flex;
    justify-content: center;
    max-width: rem($layout-base-1);
    width: 100%;
    margin: rem(96px) auto rem(65px);
    text-align: left;

    @include mq($until: wide) {
      justify-content: space-around;
    }

    @include mq($until: desktop) {
      flex-direction: column;
      align-items: center;
      margin: rem(20px) auto rem(30px);
    }

    @include mq($until: iphone-11) {
      align-items: flex-start;
    }

    .plan-collapse__left-reverse {
      @include mq($until: desktop) {
        margin-top: rem(32px);
      }
    }

    .plan-collapse__left {
      max-width: rem(312px + 120px);
      width: 100%;
      position: relative;
      padding: rem(58px 42px 45px 77px);

      @include mq($until: desktop) {
        max-width: rem(400px);
        width: 100%;
        padding: rem(58px 42px 45px 77px);
        text-align: center;
      }
      @include mq($until: small-desktop) {
        padding: rem(58px 42px 45px 42px);
      }

      @include mq($until: iphone-11) {
        padding: rem(58px 20px 42px);
      }

      > p {
        font-size: rem(24px);
        line-height: rem($text-body-height-1);
        color: $font-3;
        font-weight: 500;

        @include mq($until: desktop) {
          text-align: center;
        }

        @include mq($until: iphone-11) {
          font-size: rem(16px);
          line-height: rem(20px);
          text-align: start;
        }
      }
    }

    h2 {
      margin: 0 0 rem(8px);
      color: $font-4;

      @include mq($until: desktop) {
        text-align: center;
      }

      @include mq($until: small-desktop) {
        font-size: rem(35px);
      }

      @include mq($until: iphone-11) {
        font-size: rem(32px);
        line-height: rem(36px);
        text-align: start;
      }
    }

    .plan-collapse__right {
      text-align: center;
      margin-left: rem(100px);

      @include mq($until: desktop) {
        margin-top: rem(20px);
        margin-left: rem(0px);
      }

      @include mq($until: iphone-11) {
        padding: rem(0 20px);
        max-width: 100%;
        width: 100%;
      }

      h5 {
        margin: 0;
        color: $font-4;

        @include mq($until: iphone-11) {
          font-size: rem(24px);
          line-height: rem(28px);
        }
      }

      form {
        .input-wrapper {
          @include mq($until: iphone-11) {
            max-width: 100%;
            width: 100%;

            input {
              max-width: 100%;
              width: 100%;
            }
          }
        }

        button {
          max-width: rem(384px);

          @include mq($until: iphone-11) {
            max-width: 100% !important;
            width: 100%;
          }
        }

        .sub-button-text {
          max-width: rem(384px);
        }
      }
    }
  }

  &.plan-collapse-reverse {
    @include mq($until: desktop) {
      flex-direction: column-reverse;
    }
  }

  .plan-collapse__bg {
    position: absolute;
    top: rem(-60px);
    left: rem(-60px);

    @include mq($until: tablet) {
      display: none;
    }
  }

  .plan-collapse__left {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &.intersected {
      background-image: url('/storage/svg/decoration/pricing-decoration.svg');
    }
  }
}
