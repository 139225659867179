.empty-block {
  margin: 0 auto;
  max-width: rem(1268px);
  width: 100%;
  height: rem(230px);
  background: $white;
  box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.05);
  border-radius: 7px 7px 7px 7px;
  border: 1px solid $grey-200;
  display: flex;
  align-items: center;
  justify-content: center;

  .fresh-investigation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin: 0 auto;
      font-weight: 500;
      font-size: rem(24px);
      line-height: rem(32px);
      margin-bottom: rem(15px);
      color: $font-4;
    }

    button {
      padding: rem(20px);
      margin: 0;
      height: unset;
    }
  }
}
