@import 'styles/utils/index';

.main-container {
  > div {
    @include mq($until: wide) {
      padding: 0 rem(20px);
    }
  }

  .customer-block-container {
    background: linear-gradient(360deg, $grey-000 0%, rgba(245, 249, 252, 0) 100%);
  }

  .home-customer-block-clients {
    margin: 0 auto;
    padding: rem(54px) 0;
  }

  .content-3 .benefits-wrapper {
    margin-top: 0;
    background-color: $white;
  }

  .info-block__wrapper {
    h1 {
      max-width: rem(900px);
      width: 100%;
    }

    h5 {
      max-width: rem(640px);
      font-weight: 400;
    }
  }

  .get-data {
    h4 {
      margin: 0;
      color: $font-4;
      font-size: rem(32px);
      line-height: rem(38px);
    }
  }

  .content-main {
    margin-top: rem(192px);

    @include mq($until: iphone-11) {
      margin-top: rem(56px);
    }
  }

  .content-ahead {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: rem(800px);
    width: 100%;
    margin: 0 auto;

    h2 {
      margin: 0;
      color: $font-4;
    }

    span {
      margin-top: rem(16px);
      color: $font-3;
      font-weight: 500;
      font-size: rem(20px);
    }

    @include mq($until: small-tablet) {
      h2 {
        font-size: rem(38px);
      }
    }

    @include mq($until: iphone-11) {
      h2 {
        text-align: start;
        font-size: rem(32px);
        line-height: rem(36px);
      }

      span {
        text-align: start;
        margin-top: rem(12px);
        font-size: rem(16px);
        line-height: rem(20px);
      }
    }
  }

  .content-2 {
    background: $white-secondary;
    margin-top: rem(-180px);
    padding-top: rem(372px) !important;
    padding-bottom: rem(124px) !important;

    @include mq($until: iphone-11) {
      padding-bottom: rem(80px) !important;
    }
  }

  .feedback-container__left {
    > div:nth-child(2) {
      margin-left: rem(70px);

      @include mq($until: desktop) {
        margin-left: rem(0px);
      }
    }
  }
}
