.footer {
  margin-top: rem(192px);
  background-color: $secondary-500;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  position: relative;

  @include mq($until: tablet) {
    background-image: inherit !important;
  }

  &.footer-has-book-demo {
    min-height: rem(960px);
  }

  @include mq($until: small-desktop) {
    height: 100%;
  }

  @include mq($until: tablet) {
    margin-top: rem(50px);
  }
}

.footer-lower {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  padding: rem(64px) rem(20px) rem(20px) rem(20px);
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  color: $white;

  @include mq($until: small-desktop) {
    flex-direction: column;
    align-items: center;
  }

  @include mq($until: iphone-11) {
    align-items: flex-start;
  }

  .footer-lower__blocks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: rem(50px);

    &:nth-child(5) {
      color: red;
    }

    @include mq($until: wide) {
      margin-right: 0px;
    }

    @include mq($until: small-desktop) {
      margin-top: rem(16px);
      align-items: center;
      font-size: rem($text-body-font-2);
      line-height: rem($text-body-height-2);

      &:not(:first-child) {
        margin-top: rem(32px);
      }
    }

    @include mq($until: iphone-11) {
      align-items: flex-start;
    }

    &:nth-child(2) {
      .rc-dropdown-options-top {
        top: inherit;
        bottom: rem(32.5px);

        @include mq($until: extra-wide) {
          right: 0;
        }

        @include mq($from: iphone-11, $until: 900px) {
          right: rem(-130px) !important;
        }

        @include mq($until: iphone-11) {
          min-width: rem(214px) !important;

          &:not(.hide) {
            left: 0 !important;
          }
        }
      }
    }

    > *:not(:last-child) {
      margin-bottom: rem(20px);
    }

    img {
      cursor: pointer;
    }

    > span {
      color: $font-1;
    }

    .rc-dropdown {
      &.rc-dropdown-loading .rc-dropdown-label {
        color: #b0b6cd;

        svg path {
          stroke: #b0b6cd;
        }
      }

      .rc-dropdown-options-row {
        max-height: rem(250px);
        overflow-y: auto;
        @include slim-scroll;
      }

      .rc-dropdown-label {
        font-size: rem($text-body-font-2);
        line-height: rem($text-body-height-2);
        color: $white;

        .gdb-icon {
          width: auto;
          font-size: rem(10px);

          path {
            stroke: $white;
            stroke-width: rem(3.5px);
          }
        }
      }

      .rc-dropdown-options {
        &.rc-dropdown-options-top {
          top: inherit;
          bottom: rem(32.5px);

          @include mq($until: extra-wide) {
            right: 0;
          }

          @include mq($until: iphone-11) {
            min-width: rem(214px) !important;
            right: rem(-169px);
          }
        }

        .rc-dropdown-options-item {
          color: $font-3;
        }
      }
    }
  }

  .footer-lower__global {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .gdb-logo {
      font-size: rem(211.2px);
      height: rem(36px);
      cursor: pointer;

      svg {
        fill: none;
      }

      path {
        fill: $white;
      }
    }

    @include mq($until: small-desktop) {
      align-items: center;
      font-size: rem($text-body-font-2);
      line-height: rem($text-body-height-2);
    }

    @include mq($until: iphone-11) {
      align-items: inherit;

      .footer-lower__social-title {
        align-self: flex-start;
      }
    }
  }

  .footer-lower__number,
  .footer-lower__mail {
    margin-top: rem(16px);

    svg {
      margin-right: rem(8px);
      width: rem(14px);
      height: rem(12px);
      fill: none;

      path {
        fill: $white;
      }
    }
  }

  .footer-lower__social-media {
    margin-top: rem(16px);

    @include mq($until: small-desktop) {
      text-align: center;
    }

    @include mq($until: iphone-11) {
      text-align: start;
    }
  }

  .footer-lower__social-wrapper {
    margin-top: rem(16px);
    display: flex;

    > a {
      position: relative;
      display: block;
      margin: 0 rem(8px);
      width: rem(32px);
      height: rem(32px);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: rem(2px);

      &:hover {
        opacity: 0.9;
      }

      &:focus {
        @include focus;
      }

      &:nth-child(1) {
        margin-left: rem(0px);
      }

      svg {
        width: rem(40px);
        height: rem(40px);
        fill: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.gdb-footer-twitter {
          height: rem(14px);
          width: rem(14px);
        }

        path {
          fill: $font-4;
        }
      }
    }
  }

  a {
    color: $white;
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    color: $white;
    padding: 0;
    font-size: rem($text-body-font-2);
    line-height: rem($text-body-height-2);

    svg {
      margin-left: rem(5px);
    }
  }
}

.solutions {
  svg {
    margin-left: rem(5px);
  }
}

.footer-lower::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid $white;
  opacity: 0.2;
}

.footer-rights {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: 0 auto;
  padding: rem(131px 20px 36px);
  display: flex;
  justify-content: space-between;
  color: $white;

  @include mq($until: small-desktop) {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    margin-top: rem(0px);
    padding: rem(13px 20px 36px);
  }

  .footer-rights__left {
    margin-right: rem(35px);

    p {
      margin: rem(5px 0 0);
    }

    @include mq($until: small-desktop) {
      margin: rem(24px 0 0);
      padding-top: rem(24px);
      border-top: 1px solid rgb(255, 255, 255, 0.2);
    }
  }

  .footer-rights__right {
    display: grid;
    grid-template-columns: repeat(2, rem(174px));
    grid-column-gap: rem(22px);

    & > * {
      white-space: nowrap;
    }
  }

  .footer-rights__right {
    @include mq($until: small-desktop) {
      display: flex;
      flex-direction: column;
      padding-top: rem(24px);
      border-top: 1px solid rgb(255, 255, 255, 0.2);
    }
  }

  a {
    &:not(.link-disabled) {
      color: $white;
    }

    @include mq($until: small-desktop) {
      font-size: rem(16px);
    }
    @include mq($until: iphone-11) {
      text-align: left;
    }
  }
}

.footer-loader {
  @keyframes skeleton-pulse {
    to {
      background-position: -135% 0%;
    }
  }

  min-height: rem(1012px);
  margin-top: rem(192px);
  background-image: linear-gradient(-90deg, $secondary-500 0%, $secondary-400 20%, $secondary-500 100%) !important;
  background-size: 400% 400% !important;
  background-position: 0% 0%;
  animation: skeleton-pulse 1s ease-in-out infinite;

  @include mq($until: wide) {
    min-height: rem(949px);
  }

  @include mq($until: desktop) {
    min-height: rem(886px);
  }

  @include mq($until: small-desktop) {
    min-height: rem(1847px);
  }
}
