.topbar {
  background: $grey-000;
  padding: rem(0 15px);
  z-index: 3;
  position: fixed;
  width: 100%;
  top: 0;

  .topbar__container {
    max-width: rem($layout-base-1);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(40px);
    color: $font-3;
  }

  .topbar__info {
    display: flex;
    align-items: center;
  }

  .topbar__select {
    margin: 0 !important;

    select {
      color: $font-2 !important;
    }

    i {
      right: rem(-10px) !important;
    }

    .topbar__select-option-link .rc-dropdown-options-item {
      padding: 0;

      a {
        padding: rem(0px 5px 0px 16px);
        display: block;
        height: auto;
      }
    }

    .topbar__select-label {
      color: $font-3;
      margin-left: 0;

      b {
        font-weight: 400;

        @include mq($until: small-tablet) {
          display: none;
        }
      }

      strong {
        font-weight: 400;
        margin-left: rem(2.5px);
        color: $grey-700;
      }

      .gdb-icon path {
        stroke: $font-3;
      }
    }
  }

  .topbar__select,
  .topbar__info > div {
    margin: rem(0 16px);
  }

  select {
    border: none;
    outline: none;
    color: $font-3;
    height: rem(24px);
    appearance: none;
  }

  a:not(.rc-dropdown-options-item) {
    color: $font-3;
    height: rem(24px);
    display: flex;
    align-items: center;
    margin: rem(10px) rem(0px);
  }

  .topbar__language {
    min-width: inherit;

    .rc-dropdown-options-row {
      min-width: inherit;
      width: rem(100px);

      .rc-dropdown-options-item {
        margin-top: 0;

        &:not(.rc-dropdown-options-item-open):hover a {
          color: $main-600;
        }

        a {
          display: block;
          margin: 0;
          padding: rem(10px 5px 10px 16px);
        }
      }
    }
  }

  a {
    @include link-styling;
  }
}

.topbar-loader {
  position: relative;
  background: $grey-000;
  z-index: 3;
  height: rem(40px);
}
