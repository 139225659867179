.plan-collapse {
  &__info {
    display: flex;
    flex-direction: column;
    margin-top: rem(24px);
    color: $font-4;

    > div:nth-child(1) {
      font-size: rem(20px);
      color: $font-4;
      font-weight: 700;
    }

    @include mq($until: iphone-11) {
      align-items: flex-start;
    }
  }

  &__title {
    display: flex;
    margin-top: rem(8px);
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);

    .icon-check {
      fill: none;
      font-size: rem(20px);
      height: rem(22px);
      width: rem(22px);
    }

    > span {
      width: 100%;
      margin-left: rem(12px);
    }
  }
}
