.industries-page {
  @include mq($until: wide) {
    padding: (0 20px);
  }

  .second-country-description {
    .industry-directory__right {
      padding-top: rem(50px);
      padding-bottom: rem(50px);
      margin-left: rem(240px);
      margin-top: rem(100px);

      @include mq($until: wide) {
        margin: 0 auto;
      }
    }

    .industry-directory__left {
      max-width: rem(583px);
      width: 100%;
    }
  }

  .country-directory__blocks-wrapper {
    margin-top: rem(120px);

    @include mq($until: small-tablet) {
      margin-top: rem(190px);
    }
  }

  .content {
    margin: rem(-150px 0 -191.5px);
    padding: rem(150px 0);
    background: $white-secondary;

    @include mq($until: wide) {
      margin: rem(30px) 0 rem(-20px);
      padding: rem(70px 10px);
      border-radius: 4px;
    }

    .content__wrapper {
      margin-top: rem(220px);

      @include mq($until: wide) {
        margin: 0;
      }

      h2 {
        @include mq($until: wide) {
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }

  .automotive-industry-database {
    margin-top: rem(150px);
  }

  .request-demo {
    @include mq($until: small-tablet) {
      margin-top: rem(100px);
    }
  }
  .country-description {
    @include mq($until: small-tablet) {
      overflow: initial;
    }
    .industry-directory {
      @include mq($until: wide) {
        margin-top: rem(80px);

        h2 {
          padding-bottom: rem(30px);

          @include mq($until: small-desktop) {
            padding-bottom: rem(0px);
          }

          @include mq($until: small-tablet) {
            padding-top: rem(0px);
          }
        }
      }
    }

    .country-directory {
      @include mq($until: wide) {
        margin-bottom: rem(300px);
      }

      @include mq($until: small-desktop) {
        margin-bottom: rem(200px);
      }

      @include mq($until: small-tablet) {
        margin-bottom: rem(0px);
      }

      &__right {
        @include mq($until: small-tablet) {
          margin-bottom: rem(200px);
        }
      }
    }
  }

  .country-directory {
    h2 {
      @include mq($until: small-desktop) {
        font-size: rem(30px);
      }
    }
  }

  .country-directory__right {
    @include mq($until: small-desktop) {
      margin-top: rem(220px);
    }

    @include mq($until: small-tablet) {
      margin-top: rem(-90px);
      margin-bottom: rem(-80px);
    }
  }
  .industry-directory__right {
    @include mq($until: small-tablet) {
      padding-top: rem(0px);
    }
  }
  .industry-directory {
    @include mq($until: wide) {
      margin-top: rem(300px);
    }

    h2 {
      @include mq($until: wide) {
        padding-bottom: rem(0px);
        max-width: rem(500px);
        width: 100%;
      }

      @include mq($until: small-desktop) {
        font-size: rem(30px);
      }
    }

    &__left {
      margin-right: rem(45px);
      max-width: rem(488px);
      width: 100%;

      @include mq($until: wide) {
        padding-top: rem(40px);
        margin: 0 auto;
      }

      @include mq($until: small-desktop) {
        padding-top: rem(0px);
      }

      @include mq($until: tablet) {
        padding-bottom: rem(40px);
      }
    }

    &__right {
      padding-top: rem(50px);
      padding-bottom: rem(50px);
      margin-left: rem(180px);
      margin-top: rem(-30px);

      @include mq($until: wide) {
        margin: 0 auto;
      }
    }
  }
}
