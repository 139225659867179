.rc-info {
  display: flex;
  user-select: none;
  max-width: rem(272px);
  width: 100%;
  min-height: rem(21px);
  cursor: pointer;
  font-size: rem($text-body-font-2);
  line-height: rem($text-body-height-2);

  &:hover {
    label,
    span {
      transition: 0 all;
      color: $main-600;
    }
  }

  &.disabled {
    cursor: not-allowed;

    label,
    label span {
      color: $font-2;
      cursor: not-allowed;
    }

    svg path {
      stroke: $font-2;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-family: $font;
    font-size: rem(14px);
    letter-spacing: 0.04em;
    cursor: pointer;

    span {
      cursor: pointer;
      margin: rem(2px) 0 0;
      color: $font-2;
    }
  }

  svg {
    background: rgba($main-500, 0.1);
    padding: rem(5px);
    min-width: rem(28px);
    width: rem(28px);
    height: rem(28px);
    transform: rotate(0deg) !important;
    margin: rem(0 12px 0px 0);
  }

  .button {
    display: flex;
    box-shadow: none !important;

    &.loading.loading-link {
      label,
      span {
        color: $font-2;
      }

      svg {
        background: rgba($font-1, 0.1);

        path {
          stroke: $font-2;
        }
      }
    }

    .lds-ring {
      top: 15% !important;
      left: 100%;
      right: 0 !important;
    }
  }
}
