/** Keep it in pixels as it should the same as those from Blog */
$tablet-image-width: 176px;
$tablet-image-height: 132px;

.advantages {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: rem(56px auto);
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: rem(30px);
  justify-content: center;

  @include mq($until: small-desktop) {
    max-width: 100%;
  }

  .advantages__blocks {
    border: 1px solid $grey-100;
    padding: rem(15px);
    background: $grey-000;
    border-radius: rem(2px);

    @include mq($until: small-desktop) {
      max-width: 100%;
      display: flex;
      gap: rem(30px);
      flex-direction: unset;
      flex: unset;
    }

    @include mq($until: small-tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  .advantages__blocks-image-container {
    @include mq($until: small-desktop) {
      width: $tablet-image-width;
      height: rem($tablet-image-height);
    }

    img {
      @include mq($until: small-desktop) {
        max-width: $tablet-image-width !important;
        height: rem($tablet-image-height) !important;
      }
    }
  }

  .advantages__blocks-content {
    @include mq($until: small-tablet) {
      width: 100%;
    }
  }

  > * {
    margin: 0 !important;
  }

  .advantages__blocks {
    position: relative;
    display: flex;
    flex-direction: column;
    user-select: none;
    max-width: rem(384px);
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: center;

    @include mq($until: wide) {
      margin: 0 auto;
    }

    @include mq($until: desktop) {
      width: 100%;
    }

    @include mq($until: small-tablet) {
      max-width: rem(400px);
    }

    a {
      color: $font-4;
      @include link-styling;
    }

    /** Focusing on image */
    > a:focus {
      @include focus;
    }

    * {
      transition: all 0.1s;
    }

    .advantages__blocks-image-container {
      position: relative;

      .advantages__blocks-loading {
        left: rem(25px);
        top: rem(25px);
        z-index: 1;
      }

      .advantages__blocks-image {
        object-fit: cover;
        pointer-events: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .advantages__blocks-category {
      margin-top: rem(10px);
      font-size: rem(12px);
      letter-spacing: 0.08em;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      color: $font-3;

      a {
        color: $font-3;

        &:hover,
        &:focus {
          color: #bce;
        }

        &:focus {
          @include focus;
        }
      }

      @include mq($until: iphone-11) {
        margin-top: rem(8px);
      }
    }

    .advantages__blocks-description {
      font-weight: 400;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      color: $font-3;
      margin-top: rem(10px);

      @include mq($until: iphone-11) {
        font-size: rem(16px);
        line-height: rem(24px);
        margin: rem(6px 0 0);
      }

      > * {
        margin-top: 0;
      }
    }
  }

  .advantages__blocks-title,
  h2 {
    margin: rem(10px 0) !important;
    color: $font-4;
    font-size: rem(24px);
    text-align: left !important;
    line-height: rem(29px);

    @include mq($until: iphone-11) {
      font-size: rem(20px);
      line-height: rem(24px);
      margin: rem(6px 0);
    }

    a {
      outline: unset !important;

      &:focus:after {
        content: ' ';
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        @include focus;
      }
    }
  }

  img,
  .advantages__blocks-image {
    object-fit: contain !important;
    width: 100%;
  }
}

.advantages-empty {
  text-align: center;
  font-size: $text-body-font-1;
  line-height: $text-body-height-1;
  color: $grey-500;
}
