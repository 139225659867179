.customers-feedback {
  max-width: rem(624px);
  width: 100%;
  margin: rem(80px auto 0);
  display: flex;

  @include mq($until: desktop) {
    flex-direction: column;
    align-items: center;
  }

  @include mq($until: iphone-11) {
    margin: rem(56px auto 0);
  }

  &__left {
    position: relative;

    @include mq($until: desktop) {
      display: flex;
      justify-content: center;
      margin-bottom: rem(28px);

      img {
        margin: 0 !important;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h4 {
    margin: rem(12px 0 0);
    font-size: rem(18px);
    line-height: rem(21.87px);
    font-weight: 700;
    color: $font-4;
    letter-spacing: 0.02em;
  }

  &__right {
    margin-left: rem(32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: rem(456px);
    width: 100%;
    position: relative;

    .customers-description {
      max-width: rem(456px);
      font-size: rem(18px);
      font-weight: 400;
      line-height: rem(28.08px);
      color: $font-3;

      @include mq($until: desktop) {
        max-width: rem(464px);
      }

      @include mq($until: tablet) {
        max-width: rem(464px);
      }

      @include mq($until: iphone-11) {
        max-width: 100%;
        text-align: start;
      }
    }

    .customers-position {
      font-size: rem(14px);
      font-weight: 400;
      line-height: rem(20.72px);
      color: $font-2;
      letter-spacing: 0.04em;
    }

    .customers-description-hr {
      margin: rem(12px 0 0);
      width: 100%;
      border-bottom: rem(1px) solid #dee1ec;

      @include mq($until: tablet) {
        width: 96%;
        margin: rem(0 10px);
      }
      @include mq($until: iphone-11) {
        width: 100%;
        margin: 0;
      }
    }

    span:nth-child(3) {
      font-size: rem(18px);
      line-height: rem(22px);
      font-weight: 700;
      color: $font-4;
      padding-top: rem(12px);
    }

    span:nth-child(4) {
      min-width: rem(320px);
      line-height: rem(20.72px);
      font-size: rem(14px);
      letter-spacing: 0.04em;
      color: $font-3;
      line-height: rem($text-body-height-2);
    }

    @include mq($until: desktop) {
      margin-left: 0;
    }

    @include mq($until: tablet) {
      padding: rem(0 20px);
      margin: rem(0 90px);
      max-width: rem(452px);

      h4 {
        margin: rem(12px 10px 8px) !important;
      }

      h4,
      span {
        margin: 0 rem(10px);
        max-width: rem(442px);
      }
      .customers-feedback__buttons {
        margin-left: rem(5px);
      }
    }

    @include mq($until: iphone-11) {
      margin: 0;

      h4 {
        margin: rem(12px 0 8px) !important;
      }

      span {
        margin: 0 !important;
      }
    }
  }

  &__rectangles-left {
    position: absolute;
    bottom: rem(-97.6px);
    left: rem(-97.6px);

    svg {
      font-size: rem(336px) !important;
      height: rem(146px);
      fill: none;

      @include mq($until: 321px) {
        height: rem(184px);
      }
    }

    @include mq($until: desktop) {
      left: rem(-380.8px);
      bottom: rem(-352px);
    }

    @include mq($until: 375px) {
      left: rem(-352px);
      bottom: rem(-337.6px);
    }

    @include mq($until: 321px) {
      left: rem(-320px);
      bottom: rem(-401.6px);
    }
  }

  &__rectangles-right {
    position: absolute;
    top: rem(-25.6px);
    right: rem(-106px);

    @include mq($until: desktop) {
      top: rem(-32px);
    }

    @include mq($until: small-desktop) {
      top: rem(-32.4px);
      right: rem(-100px);
    }

    @include mq($until: small-tablet) {
      right: rem(-11.2px);
    }

    @include mq($until: 550px) {
      right: 0;
    }

    @include mq($until: 375px) {
      top: rem(-27.2px);
    }

    svg {
      font-size: rem(138px) !important;
      height: rem(287px);
      fill: none;
    }
  }

  &__buttons {
    display: flex;
    margin-top: rem(24px);
    max-width: rem(60px);
    width: 100%;
    padding: 0;
    z-index: 5;

    svg {
      width: 100%;
      max-width: 100%;
      cursor: pointer;
      align-self: center;
      font-size: rem(16px) !important;

      &:not(:first-child) {
        margin-left: rem(8px);
      }

      path {
        stroke: $font-3;
      }
    }
  }

  .icon-prev {
    fill: none;

    &:hover {
      path {
        transition: all 0.16s ease-in-out;
        stroke: $main-500;
      }
    }
  }

  .icon-next {
    fill: none;

    &:hover {
      path {
        transition: all 0.16s ease-in-out;
        stroke: $main-500;
      }
    }
  }
}

.about-us-title {
  max-width: rem(486px);
  color: $font-4;
  text-align: center;
  margin: rem(192px auto 0);

  @include mq($until: 321px) {
    font-size: rem(40px);
  }

  @include mq($until: iphone-11) {
    margin: rem(115px 0 0);
    text-align: start;
    font-size: rem(28px);
    line-height: rem(32px);
    padding: rem(0 20px);
  }
}
