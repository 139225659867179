.faq-tabs-block {
  margin-top: rem(64px);

  .faq-tabs-header {
    display: flex;
    justify-content: center;
    padding-left: rem(30px);
    margin: 0 auto;

    @include mq($until: tablet) {
      padding: rem(0px) rem(10px);
      flex-wrap: wrap;
    }

    a {
      display: block;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(24.32px);
      color: $font-3;
      white-space: nowrap;
      margin: 0 rem(16px);
      cursor: pointer;
      transition: 0.3s all;
      @include link-styling;

      @include mq($until: tablet) {
        margin: rem(10px) rem(16px);
      }

      &.active,
      &:hover {
        color: $main-500;
        border-bottom: 2px solid $main-500;
      }
    }
  }
}
