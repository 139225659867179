.policy-page {
  padding: 0 rem(15px);

  .policy-change-consent {
    span {
      display: block;

      &.link,
      &.link-disabled {
        margin-top: rem(25px);
      }
    }
  }

  .policy-description-block {
    @include regular-scroll;
    max-width: rem($layout-base-3);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow-x: auto;
    margin-top: rem(64px);

    &#declaration {
      margin-top: 0;
    }

    .policy-page-cookie {
      padding-top: rem(25px);

      &:first-child {
        margin-top: rem(35px);
      }

      &:not(:last-child) {
        margin-bottom: rem(25px);
      }

      .policy-page-cookie-empty {
        color: $grey-500;
        margin: rem(25px) 0;
        border: 1px solid #d8e5f7;
        padding: rem(10px);
        text-align: center;
      }
    }

    h2 {
      &:first-child {
        margin: 0;
      }

      margin: rem(64px) 0 0 0;
      font-size: rem(24px);
      line-height: rem(29.16px);
      font-weight: 700;
      color: $font-4;
    }

    h3 {
      max-width: rem(673px);
      margin: rem(24px) 0 0 0;
      font-size: rem(24px);
      line-height: rem(29.16px);
      font-weight: 400;
      color: $font-4;
    }

    table {
      margin: rem(24px) 0;
      border-collapse: collapse;
      border: 1px solid $grey-200;
      border-radius: rem(1px);

      @include mq($until: tablet) {
        display: block;
        border: none;
        overflow: auto;
        outline: none;
      }

      td {
        border: 1px solid $grey-200;
      }

      tbody tr {
        &:nth-child(1) {
          background-color: $grey-000;

          p {
            margin: 0 0 0 rem(10px);
            font-size: rem(14px);
            line-height: rem(20.72px);
            color: $font-4;
            line-height: rem(24px);
            padding: rem(12px) 0;
            white-space: nowrap;
            letter-spacing: 0.04em;

            strong {
              margin-right: rem(49px);
            }
          }
        }
        &:not(:nth-child(1)) {
          p {
            margin: 0 rem(10px) 0;
            font-size: rem(12px);
            line-height: rem(17.76px);
            letter-spacing: 0.04em;
          }

          td:nth-child(2) {
            a {
              color: $main-500;

              &:hover {
                color: $main-400;
              }
            }
          }

          td:nth-child(3) {
            strong {
              color: $font-3;
            }
          }

          td:nth-child(4) {
            strong {
              color: #f62942;
            }
          }

          td:nth-child(4) {
            p {
              color: $font-3;
              max-width: rem(195px);
              margin: rem(10px);
              letter-spacing: 0.04em;
            }
          }
        }
      }
    }

    ul {
      margin: 0 0 0 rem(32px);

      li {
        list-style: inherit;

        &::marker {
          position: absolute;
          content: '\2022';
          color: $main-500;
          font-size: rem(24px);
        }
        p {
          margin-top: rem(8px);
          max-width: rem(629px);
          padding-left: rem(12px);

          a {
            color: $main-500;
          }

          strong {
            &::after {
              content: '';
              display: table;
            }
          }
        }
      }
    }

    p {
      margin: rem(24px) 0 0 0;
      line-height: rem(28.08px);
      font-weight: 400;
      font-size: rem(18px);
      color: $font-3;

      strong {
        color: $font-4;
      }

      a {
        color: $font-3;
      }

      & > a {
        color: $main-500;
      }
    }

    ol {
      margin: 0;
      color: $font-4;
      font-weight: 700;
      font-size: rem(24px);
      line-height: rem(29.16px);

      li {
        margin-bottom: rem(64px);

        h3 {
          margin-top: rem(64px);
        }

        p strong {
          font-size: rem(18px);
          line-height: rem(28.08px);
          color: $font-3;
          font-weight: 400;

          a {
            color: $main-500;
          }
        }

        ul {
          margin-top: rem(24px);

          li {
            padding-left: rem(12px);
            margin: rem(8px) 0 rem(8px) 0;
            max-width: rem(629px);
            font-size: rem(18px);
            line-height: rem(28.08px);
            color: $font-3;

            ol {
              padding-top: 0;
              margin-top: rem(4px);
            }

            a {
              color: $main-500;
            }

            strong {
              max-width: 308px;
              font-size: rem(18px);
              line-height: rem(28.08px);
              color: $font-4;

              &::after {
                content: '';
                display: table;
              }
            }
          }
        }

        ol {
          padding-top: rem(24px);
          padding-left: rem(17px);
          max-width: rem(647px);
          color: $font-3;
          font-weight: 400;
          font-size: rem(18px);
          line-height: rem(28.08px);

          li {
            margin: 0 0 rem(16px) 0;
            padding-left: rem(12px);

            ol li {
              a {
                color: $font-3;
              }
              ul li {
                list-style: inherit;

                &::marker {
                  position: absolute;
                  content: '\2022';
                  color: $main-500;
                  font-size: rem(24px);
                }
              }
            }
          }
        }
      }
    }
  }
}
