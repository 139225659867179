.plan-collapse-page {
  &__api-plan-blocks {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey-200;
    padding: rem(24px 31px);
    position: relative;

    > span {
      font-size: rem(20px);
      color: $main-500;
      position: absolute;
      top: rem(20px);
      left: rem(60px);
      font-weight: 700;

      @include mq($until: extra-extra-wide) {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    h4 {
      span {
        font-size: rem(14px);
        color: $font-2;
        letter-spacing: rem(0.64px);
        line-height: 148%;
        font-weight: 400;
        position: absolute;
        top: 0px;

        @include mq($until: small-tablet) {
          font-size: rem(8px);
        }
      }
    }

    h4,
    h5 {
      margin: rem(4px 0 8px);
      color: $font-4;
      position: absolute;
      top: rem(45px);
      left: rem(60px);

      @include mq($until: extra-extra-wide) {
        position: relative;
        top: 0;
        left: 0;
      }

      @include mq($until: wide) {
        font-size: rem(20px);
      }

      @include mq($until: small-tablet) {
        font-size: rem(14px) !important;
      }
    }

    h5 {
      margin: rem(12px 0 10px);

      @include mq($until: wide) {
        margin: rem(5px 0px);
      }
    }

    .button {
      margin-top: rem(80px);

      @include mq($until: extra-extra-wide) {
        margin-top: 0;
      }

      @include mq($until: desktop) {
        min-width: rem(100px);
        height: rem(40px);
        font-size: rem(14px);
      }

      @include mq($until: small-tablet) {
        width: rem(60px);
        font-size: rem(12px);
      }
    }
  }

  &__api-plan-blocks:nth-child(2) {
    border-right: none;
    border-bottom: none;
  }

  &__api-plan-blocks:nth-child(3) {
    border-right: none;
    border-bottom: none;
  }

  &__api-plan-blocks:nth-child(4) {
    border-bottom: none;
  }
}

.plan-collapse-page__api-plan {
  h2 {
    @include mq($until: desktop) {
      font-size: rem(34px);
    }

    @include mq($until: tablet) {
      font-size: rem(28px);
      text-align: center;
    }
  }

  h5 {
    @include mq($until: desktop) {
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }

    @include mq($until: tablet) {
      font-size: rem(12px);
      padding: 0 rem(5px);
    }

    @include mq($until: small-tablet) {
      text-align: center;
    }
  }
}
