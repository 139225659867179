.fresh-investigation-wrapper {
  max-width: rem(848px);
  position: relative;
  margin: rem(0 auto 32px);
  transition: 0.2s ease;

  @include mq($until: small-desktop) {
    max-width: rem(480px);
    padding: rem(0 15px);
  }

  .delete-form {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    width: rem(32px);
    height: rem(32px);
    background-color: $main-700;
    border-radius: 2px;
    right: rem(-15px);
    top: rem(-15px);

    svg {
      margin-left: rem(1.5px);
      align-self: center;
      fill: $white;
      width: rem(16px);
    }

    @include mq($until: small-desktop) {
      right: rem(8px);
    }

    &:hover,
    &:focus {
      background: $main-600;
    }
  }

  .fresh-investigation__input-wrapper {
    padding: rem(24px);
    border: 1px solid $grey-200;
    border-radius: 2px;

    @include mq($until: small-desktop) {
      input {
        width: 100%;
      }
    }

    > *:not(:last-child) {
      margin-bottom: rem(12px);
    }

    .row {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: rem(12px);

      @include mq($until: small-desktop) {
        flex-direction: column;
      }

      .input-wrapper {
        max-width: rem(364px);
        margin: rem(12px 24px);

        @include mq($until: small-desktop) {
          margin: rem(12px auto);
        }
      }

      .select {
        align-self: center;
        width: 100%;

        .rc-select-selection-placeholder {
          padding: rem(18px 15px);
        }
      }

      .input-error-label {
        position: absolute;
        color: $danger;
        left: 40%;
        bottom: rem(12px);
        transform: translate(-50%, 50%);

        @include mq($until: small-desktop) {
          width: 100%;
          bottom: unset;
          top: rem(67.53px);
          left: 0;
          transform: initial;
          text-align: right;
        }
      }
    }

    .column {
      display: flex;

      textarea {
        resize: none;
        max-width: rem(848px);
        width: 100%;
        height: rem(120px);
        appearance: none;
        background: $white;
        border: 1px solid $grey-200;
        border-radius: rem(2px);
        outline: none;
        padding-left: rem(18px);
        padding-top: rem(18px);
        color: $font-3;
        position: static;
        font-size: rem($text-body-font-1);
        line-height: rem($text-body-height-1);
        font-family: $font;

        &::placeholder {
          top: rem(18.5px);
          left: rem(18px);
          color: $font-2;
        }

        &:focus {
          border-color: $main-500;
        }
      }
    }
  }
}
