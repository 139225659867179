@import 'utils/index';

@import 'common', 'components', 'features';

// @include mq-show-breakpoints;

body,
html {
  margin: 0;
  padding: 0;
  font-size: rem($text-body-font-2);
  line-height: rem($text-body-height-2);
  font-family: $font;
  font-style: normal;

  @include mq($until: wide) {
    font-size: rem(15px);
  }

  @include mq($until: desktop) {
    font-size: rem(14px);
  }

  @include mq($until: small-desktop) {
    font-size: rem(16px);
  }
}

body {
  padding-top: rem(142px);
  height: calc(100vh + 1px);
}

*,
::after,
::before {
  box-sizing: border-box;
}

.layout-main-container-children {
  position: relative;
  z-index: 1;
}

.iframe-extra-padding {
  @include mq($from: desktop) {
    padding-top: rem(100px);
  }
  padding-bottom: rem(100px);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: #bce;
  }

  &.light {
    color: $font-5;
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  &.link-disabled {
    cursor: not-allowed;
    color: $font-2;

    &:hover {
      color: $font-2;
    }
  }
}

h1 {
  font-size: rem(56px);
  font-weight: 700;
  line-height: rem(68px);

  @include mq($until: iphone-11) {
    font-size: rem(40px) !important;
    line-height: rem(44px) !important;
    text-align: left;
  }
}

h2 {
  font-size: rem(48px);
  font-weight: 700;
  line-height: rem(58px);

  @include mq($until: iphone-11) {
    font-size: rem(16px);
    line-height: rem(23px);
    text-align: left;
  }
}

h3 {
  font-size: rem(40px);
  font-weight: 700;
  line-height: rem(49px);
}

h4 {
  font-size: rem(32px);
  font-weight: 700;
  line-height: rem(39px);
}

h5 {
  font-size: rem(24px);
  font-weight: 700;
  line-height: rem(29px);

  @include mq($until: iphone-11) {
    font-size: rem(16px);
    line-height: rem(23px);
    text-align: left;
  }
}

.hide {
  display: none !important;
}

.block {
  display: block;
}

.not-allowed {
  cursor: not-allowed;
}

.position-fixed {
  position: fixed !important;
}

.fade-in {
  animation: fade-in 0.75s forwards;
}

.select-extra {
  display: flex;
  justify-content: space-between;

  .select-extra-label {
    margin-right: rem(23px);
    margin-left: rem(10px);
  }
}

.\!mt {
  &-124 {
    margin-top: rem(124px) !important;
  }
}

.mt {
  &-5 {
    margin-top: rem(5px);
  }

  &-20 {
    margin-top: rem(20px);
  }

  &-200 {
    margin-top: rem(200px);
  }
}

.my {
  &-20 {
    margin-top: rem(20px);
    margin-bottom: rem(20px);
  }
}

.mb {
  &-55 {
    margin-bottom: rem(55px);
  }
}

.pt {
  &-40 {
    padding-top: rem(40px);
  }
}

.\!pb {
  &-0 {
    padding-bottom: 0 !important;
  }
}

.pb {
  &-0 {
    padding-bottom: 0;
  }

  &-55 {
    padding-bottom: rem(55px);
  }
}

.m {
  &-auto {
    margin: auto;
  }
}

.w {
  &-0 {
    width: 0;
  }

  &-layout-base-1 {
    max-width: rem($layout-base-1);
    width: 100%;
  }

  &-100 {
    width: 100%;
  }
}

.color {
  // .color-font-2
  &-font-2 {
    color: $font-2;
  }
}

.link {
  cursor: pointer;
  color: $font-5;
}

.link-disabled {
  cursor: not-allowed;
  color: $font-2;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.uppercase {
  text-transform: uppercase;
}
