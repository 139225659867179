.web-tech-widget {
  display: flex;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: rem(192px) auto 0;

  @include mq($until: wide) {
    padding: 0 rem(15px);
  }

  @include mq($until: desktop) {
    display: block;
  }

  .web-tech-widget__left {
    max-width: rem(488px);
    width: 100%;

    @include mq($until: desktop) {
      margin: 0 auto;
      text-align: center;
    }

    > span {
      font-size: rem(24px);
      line-height: rem($text-body-height-3);
      color: $font-3;
    }
  }

  h2,
  h4 {
    margin: 0;
    color: $font-4;
  }

  h2 {
    margin-bottom: rem(16px);

    @include mq($until: tablet) {
      font-size: rem(35px);
    }
  }

  .web-tech-widget__right {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: rem(136px);
    position: relative;

    @include mq($until: desktop) {
      margin: 0 auto;
      width: 60%;
      padding-top: rem(70px);
    }

    @include mq($until: tablet) {
      margin-bottom: rem(30px);
    }

    @include mq($until: small-tablet) {
      width: 90%;
    }
  }

  .web-tech-widget__icon {
    position: relative;

    svg {
      position: absolute;
      left: calc(50% - 10px);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .web-tech-widget__collapse-wrapper {
    margin-top: rem(16px);
    z-index: 5;
    height: 100%;
    overflow-y: auto;
    @include regular-scroll;

    @include mq($until: tablet) {
      height: calc(100% - #{rem(85px)});
    }
  }

  .web-tech-widget__collapse {
    margin-top: rem(12.5px);
    position: relative;

    > span {
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
    }

    > span:nth-child(1) {
      color: $font-1;
      position: absolute;
      left: 0;
      top: 0;
    }

    span:nth-child(2) {
      margin-left: rem(42.5px);
      color: $font-3;
    }
  }
}
