.watch-video-loader {
  margin-left: auto;
  margin-right: auto;
  min-height: rem(775px);
  max-width: rem($layout-base-1);
  width: 100%;

  @include skeleton;
}

.watch-video {
  padding-left: rem(25px);
  padding-right: rem(25px);

  &.watch-video-layout-block {
    .watch-video__info {
      justify-content: center;
      flex-direction: column;

      .watch-video__title,
      .watch-video__text {
        max-width: rem($layout-base-3);
        text-align: center;
      }
    }
  }

  .watch-video__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: rem($layout-base-1);
    width: 100%;
    margin: 0 auto;
    gap: rem(25px);

    @include mq($until: small-desktop) {
      flex-direction: column;
    }

    @include mq($until: iphone-11) {
      align-items: flex-start;
    }
  }

  h2 {
    margin: 0;
    color: $font-4;
    font-size: rem(32px);
    line-height: rem(38px);

    @include mq($until: small-desktop) {
      margin-bottom: rem(16px);
      font-size: rem(24px);
      text-align: center;
    }

    @include mq($until: iphone-11) {
      line-height: rem(28px);
      margin-bottom: rem(12px);
      text-align: start;
    }
  }

  .watch-video__title,
  .watch-video__text {
    max-width: rem(592px);
    width: 100%;
  }

  .watch-video__text {
    color: $font-3;
    font-size: rem(20px);
    font-weight: 500;
    line-height: rem($text-body-height-3);
    max-width: rem(384px);
    width: 100%;

    @include mq($until: iphone-11) {
      line-height: rem(16px);
      line-height: rem(20px);
      text-align: start;
    }
  }

  .watch-video__video {
    margin-top: rem(32px);
    display: flex;
    justify-content: center;

    video {
      max-width: rem($layout-base-1);
      width: 100%;
      height: 100%;
    }

    @include mq($until: iphone-11) {
      margin-top: rem(24px);
    }
  }
}
