.lead-generation {
  @include mq($until: extra-wide) {
    padding: 0 rem(20px);
  }

  .video-container {
    justify-content: space-between;
    padding: 0 rem(15px);

    @include mq($until: wide) {
      h2 {
        text-align: center;
      }
    }

    &__info {
      @include mq($until: wide) {
        margin: 0;
        max-width: rem(900px);
        width: 100%;
      }
    }

    &__video {
      max-width: rem($layout-base-2);
      width: 100%;

      @include mq($until: wide) {
        margin-top: rem(40px);
      }
    }
  }

  .info-block__wrapper {
    h5 {
      max-width: rem(368px);
    }

    > a .button {
      padding-left: rem(43px);
      padding-right: rem(43px);
    }
  }

  .request-demo {
    margin-top: rem(200px);

    @include mq($until: iphone-11) {
      margin-top: rem(56px);
    }
  }

  .get-data {
    &__info {
      .get-data__text {
        padding: rem(0px);
        margin-bottom: rem(40px);

        @include mq($until: wide) {
          text-align: center;
        }

        @include mq($until: desktop) {
          padding: rem(0px 15px);
        }

        @include mq($until: iphone-11) {
          padding: 0;
          text-align: start;
        }
      }

      .get-data__button {
        display: none;
      }

      h4 {
        @include mq($until: wide) {
          text-align: center;
        }

        @include mq($until: iphone-11) {
          text-align: start;
        }
      }

      @include mq($until: small-tablet) {
        min-width: auto;
      }
    }

    &__image {
      @include mq($until: wide) {
        margin: 0 auto;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
