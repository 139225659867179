.our-benefits {
  display: flex;
  justify-content: space-between;
  max-width: rem($layout-base-1);
  width: 100%;
  margin: rem(192px) auto 0;

  @include mq($until: desktop) {
    flex-direction: column;
    align-items: center;
    margin: rem(96px 0);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(64px 32px);
    margin-left: rem(136px);

    @include mq($until: desktop) {
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
    }

    @include mq($until: tablet) {
      margin-left: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    span {
      margin-top: rem(16px);
      color: $font-3;
    }

    svg {
      font-size: rem(40px) !important;
      height: rem(40px);
      fill: none;
    }
  }

  h2 {
    margin: 0;
    color: $font-4;

    @include mq($until: small-tablet) {
      font-size: rem(35px);
      margin: rem(60px 0 60px) !important;
    }
  }
}
