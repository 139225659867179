.countries-page {
  padding: rem(0 20px);

  .country-directory {
    span {
      color: $white;
    }
    > span:nth-child(2) {
      line-height: rem(12px);
    }
  }

  .directory-profile {
    @include mq($until: wide) {
      padding-bottom: rem(120px);
    }

    @include mq($until: 880px) {
      .invert {
        flex-direction: column;

        .profile-description-text {
          margin-bottom: rem(24px);
          align-self: center;
        }
      }
    }

    @include mq($until: iphone-x) {
      .profile-description-text {
        max-width: 100%;
      }

      .touch-data-detail {
        white-space: normal;
      }
    }

    .profile-description-text-title {
      max-width: rem(544px) !important;
    }
  }

  .content {
    margin: rem(160px 0 -191.5px);
    padding: rem(60px 0 160px);
    background: $white-secondary;

    @include mq($until: wide) {
      padding-left: rem(15px);
      padding-right: rem(15px);
    }

    @include mq($until: tablet) {
      margin-bottom: rem(-48px);
    }

    @include mq($until: iphone-11) {
      padding: rem(60px 0 115px);
    }

    .asked-questions {
      padding: 0 !important;
    }

    h2 {
      @include mq($until: wide) {
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: rem(70px);
      }

      @include mq($until: iphone-11) {
        margin-bottom: rem(12px);
      }
    }

    .industry-profile {
      @include mq($until: 900px) {
        .profile-description-wrapper {
          flex-direction: column;

          .profile-description-text {
            margin-right: 0;
            margin-bottom: rem(24px);
            align-self: center;

            .profile-description-text-title {
              margin-right: 0 !important;
            }
          }
        }
      }

      @include mq($until: 540px) {
        .touch-data-wrapper {
          flex-direction: column;

          .touch-data-detail {
            padding-right: 0;
            text-align: center !important;
            margin-bottom: rem(16px) !important;
          }

          a {
            align-self: center;
          }
        }
      }

      @include mq($until: iphone-x) {
        .profile-description-text {
          max-width: 100%;
        }

        .touch-data-detail {
          white-space: normal;
        }
      }
    }

    &.alternative {
      margin: 0;

      .search-profile {
        padding-top: rem(240px);

        .profile-description-text {
          @include mq($until: wide) {
            margin-left: 0;
          }
        }

        .profile-description-wrapper {
          > *:last-child {
            align-self: center;
          }
        }
      }
    }
  }

  .email-profile {
    padding-bottom: rem(160px);

    @include mq($until: desktop) {
      .touch-data-wrapper {
        flex-direction: column;

        .touch-data-detail {
          padding-right: 0;
          align-self: center;
          margin-bottom: rem(16px) !important;
        }

        a {
          align-self: center;
        }
      }
    }

    @include mq($until: small-tablet) {
      padding-bottom: rem(260px);

      .profile-description-text {
        max-width: 100%;
      }
    }

    @include mq($until: iphone-11) {
      padding-bottom: rem(115px);
    }

    .touch-data-wrapper {
      @include mq($until: iphone-x) {
        .touch-data-detail {
          white-space: normal;
        }
      }

      button {
        margin: 0 auto;
      }
    }
  }

  .phone-profile {
    padding-bottom: rem(240px);

    @include mq($until: iphone-x) {
      .profile-description-wrapper {
        justify-content: center;
      }

      .profile-description-text {
        max-width: 100%;
      }

      .touch-data-detail {
        white-space: normal;
      }
    }
  }

  .marketing-profile {
    a {
      max-width: rem(314px);
      width: 100%;

      .touchData__button {
        white-space: nowrap;
        padding: rem(15px 20px);
        max-width: rem(314px);
        width: 100%;

        &.search {
          height: rem(64px);
        }
      }
    }

    @include mq($from: wide) {
      margin: rem(0 auto 120px);
    }

    @include mq($until: wide) {
      margin-bottom: rem(90px);
    }

    .touch-data-wrapper {
      @include mq($from: wide) {
        flex-direction: column;
      }

      @include mq($from: small-tablet, $until: tablet) {
        flex-direction: column;
      }

      .touch-data-detail {
        @include mq($from: wide) {
          text-align: center !important;
          margin-bottom: rem(16px) !important;
        }

        @include mq($from: small-tablet, $until: tablet) {
          text-align: center !important;
          margin-bottom: rem(16px) !important;
        }

        @include mq($until: small-tablet) {
          white-space: normal;
        }
      }

      a {
        @include mq($from: wide) {
          align-self: center;
        }

        @include mq($from: small-tablet, $until: tablet) {
          align-self: center;
        }
      }
    }
  }

  .gdb-icon-email-database {
    font-size: rem(325px) !important;
    height: rem(322px);
    fill: none;
  }
  .gdb-icon-business-directory {
    font-size: rem(328px) !important;
    height: rem(346px);
    fill: none;
  }
  .gdb-icon-industry-database {
    font-size: rem(351px) !important;
    height: rem(306px);
    fill: none;
  }
  .gdb-icon-phone-directory {
    font-size: rem(295px) !important;
    height: rem(310px);
    fill: none;
  }
}

.question-block-item-hidden {
  display: none;
}

.display-flex-block-item {
  padding-left: rem(30px);
  padding-right: rem(10px);

  span {
    font-size: rem($text-body-font-1);
    line-height: rem($text-body-height-1);
    font-style: normal;
    color: $font-4;
    margin-right: rem(8px);
  }

  .question-block-item {
    display: flex;
    margin-bottom: rem(10px);

    > div {
      margin: 0;
      font-size: rem($text-body-font-1);
      line-height: rem($text-body-height-1);
      color: $font-3;
    }
  }
}
