.gdb-cards {
  max-width: rem($layout-base-1);
  width: 100%;
  margin: rem(75px) auto;
  padding: rem(73px 0);
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &.intersected {
    background-image: url('/storage/svg/decoration/product-decoration.svg');
  }

  @include mq($until: desktop) {
    display: block;
    padding: rem(0 25px);
    margin: rem(75px auto 25px);
    background-image: unset !important;
  }

  @include mq($until: iphone-11) {
    padding: 0;
    margin: rem(75px 0 25px);
  }

  .gdb-card {
    width: 100%;
    border: 1px solid $grey-100;
    padding: rem(30px 27.5px);
    border-radius: rem(2px);
    background-color: $white;

    @include mq($until: desktop) {
      background-color: $white;

      &:not(:first-child) {
        margin-top: rem(20px);
      }
    }

    &:not(:last-child) {
      margin-right: rem(32px);

      @include mq($until: desktop) {
        margin-right: 0;
      }
    }

    .gdb-card-title {
      margin: rem(0 0 7.5px);
      color: $font-4;
      font-size: rem(24px);
      line-height: rem(34px);

      @include mq($until: iphone-11) {
        font-size: rem(20px);
        line-height: rem(24px);
      }
    }

    .gdb-card-description {
      color: $font-3;
      font-size: rem(18px);

      @include mq($until: iphone-11) {
        font-size: rem(16px);
        line-height: rem(24px);
      }
    }
  }
}
