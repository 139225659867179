.solution-description {
  display: flex;
  justify-content: center;

  .solution-description-stat {
    max-width: rem(384px);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $grey-000;
    border: 1px solid $grey-100;
    border-radius: 2px;
    padding: rem(40px);

    &:not(:last-child) {
      margin-right: rem(32px);
    }

    .solution-description-title {
      color: $grey-500;
      font-size: rem(16px);
      line-height: rem(24px);
      opacity: 0.8;
    }

    .solution-description-count {
      color: $main-500;
      font-size: rem(48px);
      line-height: rem(56px);
      font-weight: 700;
    }
  }
}
