@include mq-add-breakpoint('header-menu-hiding', 800px);

.header {
  border-bottom: 1px solid $grey-100;
  padding: rem(0 15px);
  background-color: $white;
  position: fixed;
  width: 100%;
  top: rem(40px);
  z-index: 2;

  &.fixed {
    position: fixed;

    .header__container {
      .header__logo {
        @include mq($until: wide) {
          padding: rem(22.5px 0) !important;
        }
      }
    }

    .header-menu-link,
    .rc-dropdown-label {
      padding: rem(28.16px 0) !important;
    }

    .rc-dropdown-options {
      margin-top: 0;
      top: rem(80.5px) !important;
    }

    .rc-dropdown-mega .rc-dropdown-options {
      top: rem(80.96px) !important;
    }
  }

  &.is-country-promotion {
    padding: rem(35px 0 30px 0);

    @include mq($until: desktop) {
      padding: rem(26px 0 22px 0);
      border-bottom: 1px solid $grey-100;
    }

    .header__container {
      padding: 0 !important;

      .header__logo {
        @include mq($until: wide) {
          padding: rem(0 15px) !important;
        }
      }
    }
  }

  .rc-dropdown-label:hover {
    ~ .rc-dropdown-options {
      display: flex !important;
    }

    .gdb-icon {
      transform: rotate(180deg);
    }
  }

  .rc-dropdown-options:hover {
    display: flex !important;
  }

  .header__contact-wrapper {
    display: flex;

    @include mq($until: wide) {
      padding: rem(0 15px);
    }

    @include mq($until: 500px) {
      flex-direction: column;
    }

    a:first-child {
      margin-right: rem(17px);
    }

    a {
      display: flex;
      color: $main-500;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(24px);

      svg {
        align-self: center;
        fill: $main-500;
        font-size: rem(14px);
        margin-right: rem(6px);
      }
    }
  }

  @include mq($until: desktop) {
    border: none;
  }

  .header__logo {
    cursor: pointer;
    margin-right: rem(100px);
    display: flex;

    @include mq($until: wide) {
      margin-right: rem(40px);
      padding: rem(34px 0);

      svg {
        font-size: rem(200px);
        height: rem(30px);
        fill: none;
      }
    }
  }

  .header__container {
    position: relative;
    max-width: rem($layout-base-1);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: desktop) {
      padding: 0;
    }

    .header-logo {
      font-size: rem(176px) !important;
      height: rem(30px);
      fill: none;
    }
  }

  .header__block {
    .menu-closed {
      svg,
      svg path {
        fill: $font-3;
      }
    }

    @include mq($until: desktop) {
      position: absolute;
      right: 0;
      top: 0;
      margin: rem(-40px 0);
    }

    .button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq($from: desktop) {
        display: none;
      }

      svg {
        width: rem(20px);
        height: rem(20px);
      }
    }
  }

  .header__menu {
    display: flex;
    flex-flow: row wrap;
    color: $font-3;
    width: 100%;

    @include mq($until: wide) {
      margin-right: rem(20px);
    }

    @include mq($until: header-menu-hiding) {
      display: none;
    }

    > div,
    > a {
      display: block;
      margin: 0 rem(16px);

      @include mq($until: wide) {
        margin: 0 rem(16px);
      }
    }

    .header-menu-link {
      padding: rem(38.56px 0);
    }

    .header__menu-item {
      align-self: center;

      &:hover {
        .rc-dropdown-label {
          color: $main-600;
        }

        svg path {
          stroke: $main-700;
        }
      }

      &.active {
        color: $main-600;

        .rc-dropdown-label {
          color: $main-600;
          position: relative;

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $main-600;
          }

          svg path {
            stroke: $main-700;
          }
        }
      }

      &.loading {
        color: $font-2;
        cursor: not-allowed;
      }
    }

    .header-industries-rows,
    .header-solutions-rows {
      padding: rem(16px 72px 37.5px);
    }

    .header-solutions-rows > * {
      width: calc(50% - #{rem(30px)}) !important;

      &:not(:last-child) {
        margin-right: rem(30px);
      }

      > *:not(:last-child) {
        margin-bottom: rem(32px);
      }
    }

    .header-browse-countries-rows,
    .header-browse-industry-rows {
      display: flex;
      padding: rem(16px 72px 32px);

      .header-browse-countries-row,
      .header-browse-industry-row {
        width: 100%;

        &:not(:last-child) {
          margin-right: rem(24px);
        }

        > *:not(:last-child) {
          margin-bottom: rem(12px);
        }
      }

      .header-browse-countries-row-item {
        width: 25%;
      }
    }

    .header-browse-countries-tab,
    .header-browse-industry-tab {
      display: flex;

      label {
        white-space: nowrap;
      }
    }

    a {
      color: $font-3;

      &:hover {
        color: $main-600;
      }
    }

    i {
      position: absolute;
      right: rem(-16px);
      top: 50%;
      transform: translateY(-50%);
    }

    .rc-dropdown-options {
      top: rem(102px);
    }
  }

  .header-menu-link,
  .rc-dropdown-label {
    transition: 0.2s ease;
  }

  select {
    border: none;
    outline: none;
    color: $font-3;
    height: rem(24px);
    appearance: none;
    line-height: rem($text-body-height-1);
  }

  .header__signin-div {
    display: flex;
    white-space: nowrap;

    @include mq($until: desktop) {
      margin-top: rem(0px);
    }

    @include mq($until: header-menu-hiding) {
      display: none;
    }

    > a {
      margin: 0 rem(8px);
    }

    > a {
      padding: rem(0 20px);
      padding-top: rem(2px);

      @include mq($until: desktop) {
        font-size: rem(14px);
      }
    }
  }

  .header__signin-demo button {
    width: rem(154px);
  }

  .header__signin button {
    width: rem(88px);
  }

  .products-list {
    .rc-dropdown-options-item {
      position: relative;
      padding-left: rem(50px);

      .rc-dropdown-options-item-icon {
        position: absolute;
        left: rem(15px);
        top: rem(10px);
        height: rem(24px);
        width: rem(24px);
        border-radius: rem(2px);
        background: #e6f2fd;

        .gdb-icon {
          height: rem(15px);
          width: rem(15px);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin: 0;

          path {
            stroke: none;
            fill: $main-700;
          }
        }
      }

      .rc-dropdown-options-item-description {
        font-size: rem(14px);
        color: $font-4;
      }
    }

    .rc-dropdown-options-item:hover {
      color: $main-600;

      .rc-dropdown-options-item-description {
        color: $main-600;
      }
    }
  }

  .hamburger-show {
    display: none;

    @include mq($until: header-menu-hiding) {
      display: block;
    }
  }

  a {
    @include link-styling;
  }
}

.header-loader {
  border-bottom: 1px solid $grey-100 !important;
  position: relative;
  z-index: 2;
  @include skeleton;
  height: rem(102.5px);
}
