.api-page {
  padding: 0 rem(20px);

  .request-demo__wrapper {
    margin-top: rem(196px);

    @include mq($until: iphone-11) {
      margin: rem(56px 0 112px);
    }
  }

  .info-block__wrapper {
    h1 {
      max-width: rem(800px);
      width: 100%;
    }

    > a .button {
      padding-left: rem(43px);
      padding-right: rem(43px);
    }
  }

  .get-data {
    // margin-top: rem(196px);

    h4 {
      margin: 0;
      color: $font-4;
      font-size: rem(48px);
      line-height: rem(58px);

      @include mq($until: tablet) {
        font-size: rem(36px);
        line-height: rem(38px);
      }
    }

    &__button {
      display: none;
    }
  }

  .get-data__info {
    @include mq($until: tablet) {
      margin-bottom: rem(50px);
    }
  }

  .api-content-3 {
    text-align: center;
    margin: rem(192px auto 0);

    .info-block__wrapper {
      h1 {
        max-width: rem(800px);
        width: 100%;
      }
    }

    .advantages {
      text-align: left;
    }

    h2 {
      margin: rem(0 0 64px);
      color: $font-4;
    }

    .get-data {
      &__info {
        @include mq($until: tablet) {
          margin-bottom: rem(20px);
        }
      }

      h4 {
        font-size: rem(48px);
        line-height: rem(58px);
        font-weight: 700;
        color: $font-4;
      }
    }
  }

  .api-page-sub-description {
    max-width: rem($layout-base-3);
    width: 100%;
    margin: rem(35px) auto 0;
    color: $font-3;
    text-align: center;
    font-weight: 500;

    @include mq($until: desktop) {
      margin: rem(75px) auto 0;
    }

    @include mq($until: iphone-11) {
      font-size: rem(16px);
      line-height: rem(20px);
      text-align: start;
    }
  }
}
